import cs from './translations/cs.json';

import validationCs from './validation/cs.json';

export const translations: Record<string, Record<string, unknown>> = {
  cs: {
    ...cs,
    ...validationCs,
  },
};
export const languages: Array<string> = Object.keys(translations);
