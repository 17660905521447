import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Form, Formik, FormikContextType } from 'formik';
import * as React from 'react';
import { FunctionComponent, RefObject } from 'react';
import { useIntl } from 'react-intl';
import FormikDebug from '../../components/FormikDebug';
import { messages } from '../../localization/messages';
import { UserRole } from '../../models/enums/UserRole';
import { cUser, UserInterface } from '../../models/User';
import RoleSelect from '../../components/RoleSelect';
import { FirebaseAuthProxyApi } from '../../components/AuthProvider/api';
import { useDispatch } from 'react-redux';
import authorSchema from '../../schema/authorSchema';

interface AuthChooseRoleFormProps {
  admins: string[];
  user: UserInterface;
  formRef: RefObject<
    FormikContextType<UserInterface & AuthChooseRoleFormAdditionalFields>
  >;
  onSubmit: (
    values: UserInterface & AuthChooseRoleFormAdditionalFields
  ) => void;
}

const useCreateInitialValues = (user: UserInterface, admins: string[]) => {
  return {
    ...user,
    roles: [UserRole.USER],

    //additionalFields
    admins: admins,
  };
};

export interface AuthChooseRoleFormAdditionalFields {
  admins: string[];
}

export enum cAuthChooseRoleFormAdditionalFields {
  isAuthorized = 'isAuthorized',
  admins = 'admins',
}

const AuthChooseRoleForm: FunctionComponent<AuthChooseRoleFormProps> = ({
  admins,
  user,
  formRef,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return (
    <Formik
      innerRef={formRef}
      onSubmit={onSubmit}
      initialValues={useCreateInitialValues(user, admins)}
      validationSchema={authorSchema}
    >
      {({ errors, touched }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box minWidth={'18rem'}>
                  <RoleSelect
                    name={cUser.roles}
                    errors={errors}
                    touched={touched}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>{formatMessage(messages.unique.roles.user)}</strong>
                </Typography>
                <Typography variant='caption'>
                  {formatMessage(messages.unique.roles.userDescription)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>
                    {formatMessage(messages.unique.roles.manager)}
                  </strong>
                </Typography>
                <Typography variant='caption'>
                  {formatMessage(messages.unique.roles.managerDesription)}
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    disableElevation
                    type='submit'
                    color='primary'
                    variant='contained'
                    fullWidth
                  >
                    {formatMessage(messages.common.actions.continue)}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography align='center' color='primary'>
                    <Button
                      disableElevation
                      color='primary'
                      variant='text'
                      fullWidth
                      onClick={() =>
                        dispatch(FirebaseAuthProxyApi.slice.actions.signOut())
                      }
                    >
                      {formatMessage(messages.actions.auth.signOut)}
                    </Button>
                  </Typography>
                  <FormikDebug />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AuthChooseRoleForm;
