import React, { FunctionComponent, lazy } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import { withWithLayoutHOC } from './components/Layout';
import { withSuspense } from './hoc/WithSuspense';
import { getLink, Links } from './links';
import { UserRole } from './models/enums/UserRole';
import AuthChooseRolePage from './pages/AuthChooseRolePage';

const AuthSelectPage = withSuspense(
  lazy(() => import('./pages/AuthSelectPage'))
);
const UnauthorizedPage = withSuspense(
  lazy(() => import('./pages/UnauthorizedPage'))
);
const AuthLoginWithEmail = withSuspense(
  lazy(() => import('./pages/AuthLoginWithEmailPage'))
);
const AuthAwaitingApproval = withSuspense(
  lazy(() => import('./pages/AuthAwaitingApprovalPage'))
);
const AuthCreateAccount = withSuspense(
  lazy(() => import('./pages/AuthCreateAccountPage'))
);
const AuthForgotPassword = withSuspense(
  lazy(() => import('./pages/AuthForgotPasswordPage'))
);
const AuthVerifyEmail = withSuspense(
  lazy(() => import('./pages/AuthVerifyEmailPage'))
);
const NotFoundPage = withSuspense(lazy(() => import('./pages/NotFoundPage')));

const ServicesPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/ServicesPage')))
);
const MyServicesPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/MyServicesPage')))
);
const MyRequestsPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/MyRequestsPage')))
);
const ApproveServicesPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/ApproveServicesPage')))
);

const ApproveUsersPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/ApproveUsersPage')))
);
const ApproveBookServiceRequests = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/ApproveBookServiceRequests')))
);
const ServiceManagementPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/ServiceManagementPage')))
);
const UserManagementPage = withWithLayoutHOC(
  withSuspense(lazy(() => import('./pages/UserManagementPage')))
);

const Router: FunctionComponent = () => (
  <Switch>
    {/* PUBLIC PAGES */}
    {/* FIXME: These should be <GuestRoute />, but then loging in isn't working.
    Today is demo and no time to fix, so fix later. */}
    <Route exact path={getLink(Links.authSelect)} component={AuthSelectPage} />
    <Route
      exact
      path={getLink(Links.loginWithEmail)}
      component={AuthLoginWithEmail}
    />
    <Route
      exact
      path={getLink(Links.createAccount)}
      component={AuthCreateAccount}
    />
    <Route
      exact
      path={getLink(Links.forgotPassword)}
      component={AuthForgotPassword}
    />
    <Route exact path={getLink(Links.notFoundPage)} component={NotFoundPage} />
    <Route
      exact
      path={getLink(Links.unauthorizedPage)}
      component={UnauthorizedPage}
    />
    {/* AUTH-ONLY PAGES */}
    <AuthRoute
      exact
      path={getLink(Links.chooseRolePage)}
      component={AuthChooseRolePage}
    />
    <AuthRoute
      exact
      path={getLink(Links.awaitingApprovalPage)}
      component={AuthAwaitingApproval}
    />
    <AuthRoute
      exact
      path={getLink(Links.verifyEmail)}
      component={AuthVerifyEmail}
    />
    <AuthRoute
      path='/'
      exact
      render={() => <Redirect to={getLink(Links.servicesPage)} />}
    />
    <AuthRoute
      path={getLink(Links.servicesPage)}
      exact
      component={ServicesPage}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.MANAGER}
      path={getLink(Links.myServicesPage)}
      component={MyServicesPage}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.ADMIN}
      path={getLink(Links.approveServicesPage)}
      component={ApproveServicesPage}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.ADMIN}
      path={getLink(Links.approveUsersPage)}
      component={ApproveUsersPage}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.ADMIN}
      path={getLink(Links.serviceManagementPage)}
      component={ServiceManagementPage}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.ADMIN}
      path={getLink(Links.userManagementPage)}
      component={UserManagementPage}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.MANAGER}
      path={getLink(Links.approveBookServiceRequests)}
      component={ApproveBookServiceRequests}
    />
    <AuthRoute
      exact
      requiredRole={UserRole.USER}
      path={getLink(Links.myRequestsPage)}
      component={MyRequestsPage}
    />
    <Redirect to={getLink(Links.notFoundPage)} />
  </Switch>
);

export default Router;
