import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { messages } from '../../localization/messages';
import { reactQueryCache } from '../../internal/config/react-query';
import { DeleteAdminAction } from '../../api/proxy/FirebaseAdminProxyAPI';
import { AdminInterface } from '../../models/Admin';
import { AdminDeleteFirebaseAdminProxyAPI } from './deleteAdminApi';
import { getAdminsQueryKey } from '../../hooks/useAdminsQuery';

interface InitialStateInterface {}

class AdminDeleteFlowAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.deleteAdminSaga = this.deleteAdminSaga.bind(this);
    this.sagas = this.sagas.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({});

  private reducer = {
    deleteAdmin(
      state: InitialStateInterface,
      action: PayloadAction<AdminInterface>
    ) {
      return state;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: AdminDeleteFlowAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryAdminDeleteContainerFlowAPIName' as string,
    initialState: AdminDeleteFlowAPI.getInitialState(),
    reducers: this.reducer,
  });

  private *deleteAdminSaga(action: PayloadAction<AdminInterface>) {
    try {
      yield put(
        AdminDeleteFirebaseAdminProxyAPI.slice.actions.delete(action.payload)
      );

      type Response = {
        fulfilled: DeleteAdminAction;
        rejected: PayloadAction<Error>;
      };

      const { fulfilled, rejected } = (yield race({
        fulfilled: take(
          AdminDeleteFirebaseAdminProxyAPI.slice.actions.deleteFulfilled.type
        ),
        rejected: take(
          AdminDeleteFirebaseAdminProxyAPI.slice.actions.deleteRejected.type
        ),
      })) as Response;

      if (fulfilled) {
        console.warn(
          messages.notifications.admin.adminDeletedSuccess.defaultMessage
        );
        yield reactQueryCache.invalidateQueries(getAdminsQueryKey());
        return;
      }

      throw rejected.payload;
    } catch (err) {
      console.warn(err);
      console.log(
        messages.notifications.admin.adminDeletedError.defaultMessage
      );
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(
          this.slice.actions.deleteAdmin.type,
          this.deleteAdminSaga
        ),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in AdminDeleteFlowAPI'
      );
    }
  }
}

export const AdminDeleteFlowApi = new AdminDeleteFlowAPI(
  'AdminDeleteFlowApiReducer'
);
