import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect/src';
import { useDispatch } from 'react-redux';
import { ReducerRegister } from '../internal/bootstrap/reducerRegistry';
import { RootState } from '../internal/redux/reducers';

export enum cReducer {
  dialog = 'dialog',
}

export type DialogRenderType = () => JSX.Element;

interface InitialStateInterface {
  [cReducer.dialog]: DialogRenderType | null;
}

class DialogAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
  }

  // Slice

  public static getInitialState = (): InitialStateInterface => ({
    dialog: null,
  });

  private reducer = {
    showDialog(
      state: InitialStateInterface,
      action: PayloadAction<DialogRenderType>
    ) {
      state[cReducer.dialog] = action.payload;
    },
    hideDialog(state: InitialStateInterface, action: Action) {
      return DialogAPI.getInitialState();
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: DialogAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryReducerName' as string,
    initialState: DialogAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeDialogRenderSelector = () =>
    createSelector<RootState, InitialStateInterface, DialogRenderType | null>(
      (state) => state[this.reducerName],
      (state) => state[cReducer.dialog]
    );

  public useDialogApi = () => {
    const dispatch = useDispatch();

    const showDialog = (renderer: () => JSX.Element): void => {
      dispatch(this.slice.actions.showDialog(renderer));
    };

    const closeDialog = () => dispatch(this.slice.actions.hideDialog());

    return {
      showDialog,
      closeDialog,
    };
  };
}

export const DialogApi = new DialogAPI('DialogApiReducer');
