import { useInfiniteQuery } from 'react-query';
import firebase from 'firebase';
import { FirebasePaginationArgs } from '../api/firebase/FirebasePagination';
import { FirebaseUserApi } from '../api/firebase/FirebaseUserAPI';
import { useRef } from 'react';

export const getUsersPaginatedQueryKey = () => 'usersPaginatedQuery';

export const useUsersPaginatedQuery = (args: FirebasePaginationArgs) => {
  const lastDoc = useRef<firebase.firestore.DocumentData | undefined>();

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
  } = useInfiniteQuery(
    [getUsersPaginatedQueryKey(), args],
    (_key, _args, prevState) => {
      if (!prevState) lastDoc.current = undefined;

      const request = FirebaseUserApi.paginate(lastDoc.current, args);

      request
        .then((response) => {
          lastDoc.current = response.docs?.[response.docs.length - 1];
        })
        .catch((err) => console.error(err));
      return request;
    },
    {
      getFetchMore: (lastGroup, allGroups) => {
        return lastGroup?.docs?.length === args.limit;
      },
    }
  );

  const items =
    data && data.map((d) => d.data).reduce((prev, next) => prev.concat(next));

  return {
    data: items,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    status,
  };
};
