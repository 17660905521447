import { Box, Container, Paper, Typography } from '@material-ui/core';
import { withAuthLayout } from 'components/AuthLayout';
import LogoVerticalWithLabel from 'components/LogoVerticalWithLabel';
import { push } from 'connected-react-router';
import { FormikContextType } from 'formik';
import { useAuthUserQuery } from 'hooks/useAuthUserQuery';
import { getLink, Links } from 'links';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import AuthChooseRoleForm, {
  AuthChooseRoleFormAdditionalFields,
} from '../../forms/AuthChooseRoleForm';
import { messages } from '../../localization/messages';
import { RequestStatus } from '../../models/enums/RequestStatus';
import { UserInterface } from '../../models/User';
import Logo from '../../resources/images/mapa-pomoci-switched-colors.svg';
import { UserChangeRoleFlowApi } from './flow';
import { useAdminsQuery } from '../../hooks/useAdminsQuery';
import SuspenseSpinner from '../../components/SuspenseSpinner';
import { AdminInterface } from '../../models/Admin';

const AuthChooseRolePageContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const formRef = useRef<
    FormikContextType<UserInterface & AuthChooseRoleFormAdditionalFields>
  >(null);
  const { isLoading: userLoading, data: user } = useAuthUserQuery();
  const { data: admins, isLoading } = useAdminsQuery();

  useEffect(() => {
    if (
      user?.verified &&
      user?.status === RequestStatus.ACTIVE &&
      user.roles.length > 0
    )
      dispatch(push(getLink(Links.servicesPage)));
  }, [dispatch, user, userLoading]);

  let adminEmails: string[];
  if (admins === undefined) {
    adminEmails = [];
  } else {
    adminEmails = admins.map((admin: AdminInterface) => admin.id);
  }

  if (isLoading) return <SuspenseSpinner />;

  return (
    <Container maxWidth='xs' component={Paper}>
      <Box py={2}>
        <LogoVerticalWithLabel logo={Logo} />
        <Box m={2}>
          <Typography align='center'>
            {formatMessage(messages.unique.auth.chooseRoleTitle)}
          </Typography>
        </Box>
        <Box my={1}>
          <AuthChooseRoleForm
            admins={adminEmails}
            user={user}
            formRef={formRef}
            onSubmit={(values) =>
              dispatch(
                UserChangeRoleFlowApi.slice.actions.userChangeRole({
                  ...values,
                })
              )
            }
          />
        </Box>
      </Box>
    </Container>
  );
};

export default withAuthLayout(AuthChooseRolePageContainer);
