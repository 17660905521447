import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { getAuthUserQueryKey } from 'hooks/useAuthUserQuery';
import { reactQueryCache } from 'internal/config/react-query';
import { getLink, Links } from 'links';
import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import { AlertApi } from '../../api/AlertAPI';
import { InitialUserRoleSelectionFulfilled } from '../../api/proxy/FirebaseUserProxyAPI';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { messages } from '../../localization/messages';
import { UserInterface } from '../../models/User';
import { UserChangeRoleFirebaseUserProxyAPI } from './api';
import { AuthChooseRoleFormAdditionalFields } from '../../forms/AuthChooseRoleForm';

interface InitialStateInterface {}

class UserChangeRoleFlowAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.userChangeRoleSaga = this.userChangeRoleSaga.bind(this);
    this.sagas = this.sagas.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({});

  private reducer = {
    userChangeRole(
      state: InitialStateInterface,
      action: PayloadAction<UserInterface & AuthChooseRoleFormAdditionalFields>
    ) {
      return state;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: UserChangeRoleFlowAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryUserChangeRoleFlowAPIName' as string,
    initialState: UserChangeRoleFlowAPI.getInitialState(),
    reducers: this.reducer,
  });

  private *userChangeRoleSaga(
    action: PayloadAction<UserInterface & AuthChooseRoleFormAdditionalFields>
  ) {
    try {
      yield put(
        UserChangeRoleFirebaseUserProxyAPI.slice.actions.initialRoleSelection(
          action.payload
        )
      );
      type Response = {
        fulfilled: InitialUserRoleSelectionFulfilled;
        rejected: PayloadAction<Error>;
      };

      const { fulfilled, rejected } = (yield race({
        fulfilled: take(
          UserChangeRoleFirebaseUserProxyAPI.slice.actions
            .initialRoleSelectionFulfilled.type
        ),
        rejected: take(
          UserChangeRoleFirebaseUserProxyAPI.slice.actions
            .initialRoleSelectionRejected.type
        ),
      })) as Response;

      if (fulfilled) {
        yield reactQueryCache.invalidateQueries(getAuthUserQueryKey());
        yield put(push(getLink(Links.servicesPage)));
        return;
      }

      throw rejected.payload;
    } catch (err) {
      console.error(err);
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'error',
          message: messages.notifications.user.updateErrorMessage.id,
        })
      );
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(
          this.slice.actions.userChangeRole.type,
          this.userChangeRoleSaga
        ),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in UserChangeRoleFlowAPI'
      );
    }
  }
}

export const UserChangeRoleFlowApi = new UserChangeRoleFlowAPI(
  'userChangeRoleFlowApiReducer'
);
