import { UserRole } from './enums/UserRole';
import { RequestStatus } from './enums/RequestStatus';

export enum cUser {
  id = 'id',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  photo = 'photo',
  roles = 'roles',
  fullName = 'fullName',
  createdAt = 'createdAt',
  phone = 'phone',
  verified = 'verified',
  status = 'status',
  receiveMail = 'receiveMail',
}

export interface UserInterface {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  photo?: string;
  roles: UserRole[];
  fullName: string;
  createdAt: Date;
  phone?: string | null;
  verified: boolean;
  status: RequestStatus;
  receiveMail: boolean;
}

export const User = (): UserInterface => ({
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  roles: [],
  fullName: '',
  createdAt: new Date(),
  verified: false,
  status: RequestStatus.REQUESTED,
  receiveMail: false,
});
