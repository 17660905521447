import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import { AlertApi } from '../../api/AlertAPI';
import { DialogApi } from '../../api/DialogAPI';
import {
  UpdateUserAction,
  UpdateUserFulfilled,
} from '../../api/proxy/FirebaseUserProxyAPI';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { reactQueryCache } from '../../internal/config/react-query';
import { messages } from '../../localization/messages';
import { getUsersPaginatedQueryKey } from '../../hooks/useUsersPaginatedQuery';
import { getAuthUserQueryKey } from '../../hooks/useAuthUserQuery';
import { UserUpdateFirebaseUserProxyAPI } from './api';

interface InitialStateInterface {}

class UserUpdateFlowAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.updateUserSaga = this.updateUserSaga.bind(this);
    this.sagas = this.sagas.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({});

  private reducer = {
    updateUser(state: InitialStateInterface, action: UpdateUserAction) {
      return state;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: UserUpdateFlowAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryUserUpdateFlowAPIName' as string,
    initialState: UserUpdateFlowAPI.getInitialState(),
    reducers: this.reducer,
  });

  private *updateUserSaga(action: UpdateUserAction) {
    try {
      yield put(
        UserUpdateFirebaseUserProxyAPI.slice.actions.update(action.payload)
      );

      type Response = {
        fulfilled: UpdateUserFulfilled;
        rejected: PayloadAction<Error>;
      };

      const { fulfilled, rejected } = (yield race({
        fulfilled: take(
          UserUpdateFirebaseUserProxyAPI.slice.actions.updateFulfilled.type
        ),
        rejected: take(
          UserUpdateFirebaseUserProxyAPI.slice.actions.updateRejected.type
        ),
      })) as Response;

      if (fulfilled) {
        yield put(
          AlertApi.slice.actions.showAlert({
            type: 'success',
            message: messages.notifications.user.updateSuccessMessage.id,
          })
        );
        yield put(DialogApi.slice.actions.hideDialog());

        yield reactQueryCache.invalidateQueries(getUsersPaginatedQueryKey());
        yield reactQueryCache.invalidateQueries(getAuthUserQueryKey());

        return;
      }

      throw rejected.payload;
    } catch (err) {
      console.error(err);
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'error',
          message: messages.notifications.user.updateErrorMessage.id,
        })
      );
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(
          this.slice.actions.updateUser.type,
          this.updateUserSaga
        ),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in UserUpdateFlowAPI'
      );
    }
  }
}

export const UserUpdateFlowApi = new UserUpdateFlowAPI(
  'userUpdateFlowApiReducer'
);
