import firebase from 'firebase';
import { cUser, UserInterface } from 'models/User';
import { cService } from '../../models/Service';
import {
  FirebasePaginationArgs,
  FirebasePaginationInterface,
  withFirebaseFilter,
} from './FirebasePagination';
import merge from 'deepmerge';

const collection = 'users';

class FirebaseUserAPI {
  getAll(): Promise<UserInterface[]> {
    return new Promise((resolve, reject) => {
      const firebaseRef = firebase.firestore().collection(collection);

      firebaseRef
        .get()
        .then((doc) => {
          resolve(
            doc.docs.map((d) => {
              const data = d.data();

              return {
                ...data,
                id: d.id,
                createdAt: data[cService.createdAt].toDate(),
              };
            }) as UserInterface[]
          );
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  paginate(
    lastDocument: firebase.firestore.DocumentData | undefined,
    args?: FirebasePaginationArgs
  ): Promise<FirebasePaginationInterface<UserInterface>> {
    const defaultParams = {
      limit: 10,
      filters: {
        orderBy: [],
        where: [],
      },
    };

    const params: FirebasePaginationArgs = args
      ? merge(defaultParams, args)
      : defaultParams;

    return new Promise((resolve, reject) => {
      let query = firebase
        .firestore()
        .collection(collection)
        .limit(params.limit as number);

      if (params.filters) {
        query = withFirebaseFilter(query, params.filters);
      }

      if (lastDocument) {
        query = query.startAfter(lastDocument);
      }

      query
        .get()
        .then((doc) => {
          const data = doc.docs.map((d) => ({
            ...d.data(),
            id: d.id,
            createdAt: d.data()[cUser.createdAt].toDate(),
          })) as UserInterface[];

          resolve({ data, docs: doc.docs });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return firebase.firestore().doc(`${collection}/${id}`).delete();
  }

  update(src: UserInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      const firebaseRef = firebase.firestore().doc(`${collection}/${src.id}`);

      if (!src.id) {
        console.warn('Data for create endpoint should contain ID field');
      }

      return firebaseRef
        .update(src)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
}

export const FirebaseUserApi = new FirebaseUserAPI();
