import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect/src';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { RootState } from '../../internal/redux/reducers';
import FirebaseMailAPI from '../firebase/FirebaseMailAPI';
import { useSelector } from 'react-redux';
import { MailInterface } from '../../models/Mail';
import { AlertApi } from '../AlertAPI';
import { messages } from '../../localization/messages';

export enum cReducer {
  loading = 'loading',
  initialized = 'initialized',
}

interface InitialStateInterface {
  [cReducer.loading]: boolean;
  [cReducer.initialized]: boolean;
}

export type CreateMailAction = PayloadAction<MailInterface>;
export type CreateEventFulfilled = PayloadAction<unknown>;

export class FirebaseMailProxyAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.sagas = this.sagas.bind(this);
    this.createSaga = this.createSaga.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({
    [cReducer.loading]: true,
    [cReducer.initialized]: false,
  });

  private reducer = {
    create(state: InitialStateInterface, action: CreateMailAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    createFulfilled(
      state: InitialStateInterface,
      action: CreateEventFulfilled
    ) {
      state[cReducer.loading] = false;
    },
    createRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: FirebaseMailProxyAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryFirebaseEventProxyAPIName' as string,
    initialState: FirebaseMailProxyAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeLoadingSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.loading]
  );

  public makeInitializedSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.initialized]
  );

  public useFirebaseEventProxyApi() {
    const loading = useSelector(this.makeLoadingSelector);
    const initialized = useSelector(this.makeInitializedSelector);

    return {
      loading: loading && initialized,
    };
  }

  // Saga

  private *createSaga(action: CreateMailAction) {
    try {
      const response = yield call(() => FirebaseMailAPI.create(action.payload));

      yield put(this.slice.actions.createFulfilled(response));

      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'success',
          message: messages.notifications.email.sent.id,
        })
      );
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.createRejected(err));

      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'error',
          message: messages.notifications.email.notSent.id,
        })
      );
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(this.slice.actions.create.type, this.createSaga),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in FirebaseEventProxyAPI',
        err
      );
    }
  }
}
