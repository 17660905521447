import {
  Box,
  Button,
  IconButton,
  Link as MaterialLink,
  ListSubheader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import {
  AssignmentTurnedIn,
  Business,
  DomainDisabled,
  EventSeat,
  GroupAdd,
  LibraryAddCheck,
  MailOutline,
  PersonAddDisabled,
  ViewList,
} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import cx from 'classnames';
import clsx from 'clsx';
import { UserMenu } from 'components/UserMenu';
import { useAuthUserQuery } from 'hooks/useAuthUserQuery';
import { messages, TranslateMessage } from 'localization/messages';
import React, { ComponentType, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useCookieCache } from '../../hooks/useCookieCache';
import { getLink, Links } from '../../links';
import Logo from '../../resources/images/mapa-pomoci-color.svg';
import UnstyledLink from '../UnstyledLink';
import { useStyles } from './styles';
import { UserRole } from '../../models/enums/UserRole';
import AuthComponent from '../AuthComponent';
import krasaLogo from '../../resources/images/krasapomoci.svg';
import developedByTietoEvry from '../../resources/images/developedByTietoEvry.svg';

interface SidebarLinkInterface {
  text: { id: string; defaultMessage: string };
  icon: React.ReactNode;
  link: string;
  requiredRole?: UserRole;
}

const sidebarPublicLinks: SidebarLinkInterface[] = [
  {
    text: messages.unique.sidebar.services,
    icon: <ViewList />,
    link: getLink(Links.servicesPage),
  },
];

const sidebarUserLinks: SidebarLinkInterface[] = [
  {
    text: messages.unique.sidebar.myRequests,
    icon: <EventSeat />,
    link: getLink(Links.myRequestsPage),
    requiredRole: UserRole.USER,
  },
];

const sidebarManagerLinks: SidebarLinkInterface[] = [
  {
    text: messages.unique.sidebar.myServices,
    icon: <Business />,
    link: getLink(Links.myServicesPage),
    requiredRole: UserRole.MANAGER,
  },
  {
    text: messages.unique.sidebar.approveBookServiceRequests,
    icon: <AssignmentTurnedIn />,
    link: getLink(Links.approveBookServiceRequests),
    requiredRole: UserRole.MANAGER,
  },
];

const sidebarAdminLinks: SidebarLinkInterface[] = [
  {
    text: messages.unique.sidebar.newServices,
    icon: <LibraryAddCheck />,
    link: getLink(Links.approveServicesPage),
    requiredRole: UserRole.ADMIN,
  },
  {
    text: messages.unique.sidebar.newUsers,
    icon: <GroupAdd />,
    link: getLink(Links.approveUsersPage),
    requiredRole: UserRole.ADMIN,
  },
  {
    text: messages.unique.sidebar.manageServices,
    icon: <DomainDisabled />,
    link: getLink(Links.serviceManagementPage),
    requiredRole: UserRole.ADMIN,
  },
  {
    text: messages.unique.sidebar.manageUsers,
    icon: <PersonAddDisabled />,
    link: getLink(Links.userManagementPage),
    requiredRole: UserRole.ADMIN,
  },
];

interface MenuSection {
  links: SidebarLinkInterface[];
  subheader: TranslateMessage;
  displayHeader: boolean;
  requiredRole?: UserRole;
}

const menus: MenuSection[] = [
  {
    links: sidebarPublicLinks,
    subheader: messages.unique.sidebar.publicData,
    displayHeader: false,
  },
  {
    links: sidebarUserLinks,
    subheader: messages.unique.sidebar.myData,
    displayHeader: false,
    requiredRole: UserRole.USER,
  },
  {
    links: sidebarManagerLinks,
    subheader: messages.unique.sidebar.myData,
    displayHeader: true,
    requiredRole: UserRole.MANAGER,
  },
  {
    links: sidebarAdminLinks,
    subheader: messages.unique.sidebar.adminData,
    displayHeader: true,
    requiredRole: UserRole.ADMIN,
  },
];

interface AppbarProps {
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  open: boolean;
}

const AppbarLarge: FunctionComponent<AppbarProps> = ({
  handleDrawerClose,
  handleDrawerOpen,
  open,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { data: userData, isLoading: userIsLoading } = useAuthUserQuery();

  return (
    <AppBar
      position='absolute'
      variant='outlined'
      className={cx([classes.appbar, classes.appbarSidebar])}
    >
      <Toolbar className={classes.appbarToolbar}>
        <UnstyledLink to={getLink(Links.servicesPage)}>
          <img src={Logo} alt='Logo' width={140} />
        </UnstyledLink>
        <div style={{ flexGrow: 1 }} />
        {!userIsLoading &&
          (userData ? (
            <UserMenu user={userData} />
          ) : (
            <UnstyledLink to={getLink(Links.authSelect)}>
              <Button endIcon={<ExitToAppIcon />}>
                {formatMessage(messages.actions.auth.signIn)}
              </Button>
            </UnstyledLink>
          ))}
      </Toolbar>
    </AppBar>
  );
};

const AppbarSmall: FunctionComponent<AppbarProps> = ({
  handleDrawerClose,
  handleDrawerOpen,
  open,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { data: userData, isLoading: userIsLoading } = useAuthUserQuery();

  return (
    <AppBar
      position='absolute'
      variant='outlined'
      className={cx([classes.appbar, classes.appbarContent])}
    >
      <Toolbar className={classes.appbarToolbar}>
        <IconButton onClick={handleDrawerClose} hidden={!open}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton onClick={handleDrawerOpen} hidden={open}>
          <MenuIcon />
        </IconButton>
        <UnstyledLink to={getLink(Links.servicesPage)}>
          <img src={Logo} alt='Logo' width={130} />
        </UnstyledLink>
        <div style={{ flexGrow: 1 }} />
        {!userIsLoading &&
          (userData ? (
            <UserMenu user={userData} />
          ) : (
            <UnstyledLink to={getLink(Links.authSelect)}>
              <Button endIcon={<ExitToAppIcon />}>
                {formatMessage(messages.actions.auth.signIn)}
              </Button>
            </UnstyledLink>
          ))}
      </Toolbar>
    </AppBar>
  );
};

const Layout: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const {
    value: isSidebarOpen,
    setValue: setSidebarOpenCache,
  } = useCookieCache('sidebarOpen', false);
  const [open, setOpen] = React.useState(isSidebarOpen);
  const { formatMessage } = useIntl();

  const handleDrawerOpen = () => {
    setOpen(true);
    setSidebarOpenCache(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSidebarOpenCache(false);
  };
  const location = useLocation();

  return (
    <div className={classes.outline}>
      <div className={classes.root}>
        <AppbarLarge
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
        />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.sidebarDrawerButton}>
            <List>
              <ListItem button onClick={handleDrawerClose} hidden={!open}>
                <ListItemIcon>
                  <ChevronLeftIcon />
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={handleDrawerOpen} hidden={open}>
                <ListItemIcon>
                  <MenuIcon />
                </ListItemIcon>
              </ListItem>
            </List>
            <Divider />
          </div>

          {menus.map((menu, j) => {
            const navigations = menu.links.map((link, i) => (
              <Box
                style={{
                  color:
                    location.pathname === link.link ? 'primary' : 'inherit',
                }}
                key={i}
              >
                <UnstyledLink key={i} to={link.link}>
                  <Tooltip
                    title={
                      !open
                        ? `${formatMessage(menu.subheader)} - ${formatMessage(
                            link.text
                          )}`
                        : ''
                    }
                    placement='right'
                    arrow
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <Typography
                          color={
                            location.pathname === link.link
                              ? 'primary'
                              : 'inherit'
                          }
                        >
                          {link.icon}
                        </Typography>
                      </ListItemIcon>

                      <ListItemText primary={formatMessage(link.text)} />
                    </ListItem>
                  </Tooltip>
                </UnstyledLink>
              </Box>
            ));

            return (
              <AuthComponent requiredRole={menu.requiredRole} key={j}>
                <List
                  key={j}
                  subheader={
                    open && menu.displayHeader ? (
                      <ListSubheader>
                        {formatMessage(menu.subheader)}
                      </ListSubheader>
                    ) : undefined
                  }
                >
                  {navigations}
                </List>
                <Divider />
              </AuthComponent>
            );
          })}
        </Drawer>
        <main className={classes.content}>
          <AppbarSmall
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        padding={2}
      >
        <Box
          display='flex'
          justifyContent='flex-start'
          style={{
            color: 'white',
          }}
          component={(props) => (
            <MaterialLink
              {...props}
              color='inherit'
              href='mailto:info@krasapomoci.cz'
            />
          )}
        >
          <MailOutline style={{ paddingRight: 5 }} />
          info@krasapomoci.cz
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <img src={developedByTietoEvry} alt='Logo' width={80} />
          <img src={krasaLogo} alt='Logo' width={80} />
        </Box>
      </Box>
    </div>
  );
};

export const withWithLayoutHOC = (
  WrappedComponent: ComponentType
): FunctionComponent => (props) => (
  <Layout>
    <WrappedComponent {...props} />
  </Layout>
);

export default Layout;
