import Cookies, { CookieAttributes } from 'js-cookie';

export const useCookieCache = <V>(
  key: string,
  defaultValue: V,
  args?: CookieAttributes
) => {
  const cacheValue = Cookies.get(key);
  const value: V = cacheValue ? JSON.parse(cacheValue) : defaultValue;
  const setValue = (value: V) =>
    Cookies.set(key, JSON.stringify(value), { expires: 365, ...args });

  return {
    value,
    setValue,
  };
};
