import firebase from 'firebase';
import { AdminInterface } from '../../models/Admin';

const collection = 'admins';

class FirebaseAdminAPI {
  //creates a new admin, if one with the same Id exists it overwrites it
  create(src: AdminInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      const firebaseRef = firebase.firestore().collection(collection);

      firebaseRef
        .doc(src.id)
        .set(src)
        .then((doc) => resolve())
        .catch((err) => reject(err));
    });
  }

  getAll(): Promise<AdminInterface[]> {
    return new Promise((resolve, reject) => {
      const firebaseRef = firebase.firestore().collection(collection);

      firebaseRef
        .get()
        .then((doc) => {
          resolve(
            doc.docs.map((d) => {
              const data = d.data();

              return {
                ...data,
                id: d.id,
              };
            }) as AdminInterface[]
          );
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  delete(id: string): Promise<void> {
    return firebase.firestore().doc(`${collection}/${id}`).delete();
  }
}

export default new FirebaseAdminAPI();
