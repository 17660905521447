import { Saga } from 'redux-saga';

/**
 * Service used for registering sagas on runtime. Instead of registering manually
 * sagas for each API, the saga will be only one with multiple sagas stored
 * under different unique keys
 */
class _SagaRegister {
  private emitChange: {
    (arg: Saga): void;
    (saga: Saga): void;
  } | null;

  private sagas: Record<string, Saga>;
  constructor() {
    this.emitChange = null;
    this.sagas = {};
  }

  getSagas() {
    return { ...this.sagas };
  }

  register(name: string, saga: Saga) {
    if (this.sagas[name]) {
      return;
    }
    this.sagas = { ...this.sagas, [name]: saga };
    if (this.emitChange) {
      this.emitChange(saga);
    }
  }

  setChangeListener(listener: (saga: Saga) => void) {
    this.emitChange = listener;

    // sometimes some sagas are registered sooner than "setChangeListener" was initialized
    // so that's why im registering all sagas when "setChangeListener" is set
    Object.keys(this.sagas).forEach((saga) => {
      this.emitChange && this.emitChange(this.sagas[saga]);
    });
  }
}

export const SagaRegister = new _SagaRegister();
