import * as React from 'react';
import { FunctionComponent, RefObject } from 'react';
import { Form, Formik, FormikContextType } from 'formik';
import FormikDebug from '../../components/FormikDebug';
import { useIntl } from 'react-intl';
import { cUser, UserInterface } from '../../models/User';
import RoleSelect from '../../components/RoleSelect';
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBaseComponentProps,
} from '@material-ui/core';
import CardField from '../../components/CardField';
import { messages } from '../../localization/messages';
import { FormikTextField } from '../../components/FormikFields';
import {
  NumberFormatCustom,
  NumberFormatCustomProps,
} from '../../components/CustomNumberFormat';
import authorSchema from '../../schema/authorSchema';
import { UpdateUserAdditionalFields } from '../../containers/ApproveUsersPageContainer';
import { UserRole } from '../../models/enums/UserRole';

interface UserUpdateFormProps {
  formRef: RefObject<
    FormikContextType<UserInterface & UpdateUserAdditionalFields>
  >;
  onSubmit: (values: UserInterface & UpdateUserAdditionalFields) => void;
  user: UserInterface;
}

export const phoneFormat = (props: NumberFormatCustomProps) => (
  <NumberFormatCustom {...props} type={'tel'} format={'### ### ###'} />
);

const useCreateInitialValues = (user: UserInterface) => {
  return {
    ...user,
    //additionalFields
    originalStatus: user.status,
  };
};

const UserUpdateForm: FunctionComponent<UserUpdateFormProps> = ({
  user,
  formRef,
  onSubmit,
  children,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Formik
      innerRef={formRef}
      onSubmit={onSubmit}
      initialValues={useCreateInitialValues(user)}
      validationSchema={authorSchema}
    >
      {({ errors, touched, handleChange, values }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box
                  display='flex'
                  minHeight='100%'
                  justifyContent='center'
                  alignItems={'center'}
                >
                  <Avatar
                    style={{
                      height: '100px',
                      width: '100px',
                    }}
                    src={user?.photo}
                    alt='user-avatar'
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CardField
                  title={formatMessage(messages.models.users.fullName)}
                  value={user.fullName}
                />
                <CardField
                  title={formatMessage(messages.models.users.email)}
                  value={user.email}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikTextField
                  name={cUser.phone}
                  label={`${formatMessage(messages.models.users.phone)}`}
                  InputProps={{
                    inputComponent: (phoneFormat as unknown) as FunctionComponent<InputBaseComponentProps>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box minWidth={'28rem'}>
                  <RoleSelect
                    name={cUser.roles}
                    errors={errors}
                    touched={touched}
                  />
                </Box>
              </Grid>
              {children}
              <Grid item xs={12}>
                {values.roles.includes(UserRole.ADMIN) ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={'primary'}
                        onChange={handleChange}
                        name={cUser.receiveMail}
                        checked={values.receiveMail}
                      />
                    }
                    label={formatMessage(
                      messages.unique.services.receiveEmailsConfirmation
                    )}
                  />
                ) : null}
              </Grid>
              <FormikDebug />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserUpdateForm;
