import { FirebaseAuthAPI } from 'api/firebase/FirebaseAuthAPI';
import { useQuery } from 'react-query';
import { UserInterface } from '../models/User';

export const getAuthUserQueryKey = () => ['authUserQuery'];

export const useAuthUserQuery = () => {
  const { isLoading, error, data } = useQuery(getAuthUserQueryKey(), () =>
    FirebaseAuthAPI.authAPI.getCurrentUser()
  );

  return {
    isLoading,
    error,
    data: data as UserInterface, // There is middleware checking if we are really auth, so we expect to always have user here
  };
};
