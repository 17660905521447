import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { messages } from 'localization/messages';
import { UserInterface } from 'models/User';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { FirebaseAuthProxyApi } from '../AuthProvider/api';
import { ArrowDropDown, Create } from '@material-ui/icons';
import UserUpdateFormDialog from '../../dialogs/UserUpdateFormDialog';
import { DialogApi } from '../../api/DialogAPI';
import { useDispatch } from 'react-redux';

interface UserMenuProps {
  user: UserInterface;
}

export const UserMenu: FunctionComponent<UserMenuProps> = ({ user }) => {
  const { showDialog } = DialogApi.useDialogApi();
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    loading: apiLoading,
  } = FirebaseAuthProxyApi.useFirebaseAuthProxyAPI();

  return (
    <div>
      <Button
        aria-controls='user-menu'
        aria-haspopup='true'
        variant='text'
        onClick={handleClick}
      >
        <Box display='flex' alignItems='center'>
          <Avatar src={user?.photo} alt='user-avatar' />
          <Box ml={1}>
            <Typography variant='caption'>
              <strong>{user.fullName}</strong>
            </Typography>
          </Box>
          <ArrowDropDown color='primary' />
        </Box>
      </Button>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() =>
            showDialog(() => (
              <UserUpdateFormDialog user={user} isStatusSelect={false} />
            ))
          }
          disabled={apiLoading}
        >
          <ListItemText
            primary={formatMessage(messages.actions.user.updateProfile)}
          />
          <ListItemIcon style={{ justifyContent: 'flex-end' }}>
            <Create fontSize='small' />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={() => dispatch(FirebaseAuthProxyApi.slice.actions.signOut())}
          disabled={apiLoading}
        >
          <ListItemText
            primary={formatMessage(messages.actions.auth.signOut)}
          />
          <ListItemIcon style={{ justifyContent: 'flex-end' }}>
            <ExitToAppIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
};
