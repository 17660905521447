import { setLocale } from 'yup';

export type CustomValidationError = {
  key: string;
  values: Record<string, unknown>;
};

type ValidatorErrorArgs = {
  [key: string]: unknown;
  message?: string;
};

/**
 * Implement on a fly when needed... Get implementation details of types and variables from link below
 * Implementation details: https://github.com/jquense/yup/blob/master/src/locale.js
 *
 * Steps:
 * 1. Update method in setLocale
 * 2. Update validation strings in localization/validation
 * 3. Optional - if custom message is requested, extend /types/validators.d.ts, library/extensions/yup/validators.ts
 */
export const customValidationErrors = {
  // use constant translation keys for messages without values
  mixed: {
    default: ({ path }: ValidatorErrorArgs): CustomValidationError => ({
      key: 'Validation.Mixed.Default',
      values: { path },
    }),
    required: ({ path }: ValidatorErrorArgs): CustomValidationError => ({
      key: 'Validation.Mixed.Required',
      values: { path },
    }),
    nonType: ({ type }: ValidatorErrorArgs): CustomValidationError => ({
      key: 'Validation.Mixed.NotType',
      values: { type },
    }),
    oneOf: ({
      path,
      values,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Mixed.OneOf',
      values: { path, values },
    }),
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({
      min,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Number.Min',
      values: { min, path },
    }),
    max: ({
      max,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Number.Max',
      values: { max, path },
    }),
    lessThan: ({
      less,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Number.LessThan',
      values: { less, path },
    }),
    moreThan: ({
      more,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Number.MoreThan',
      values: { more, path },
    }),
  },

  date: {
    min: ({
      min,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Date.Min',
      values: {
        min,
        path,
      },
    }),
    max: ({
      max,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.Date.Max',
      values: {
        max,
        path,
      },
    }),
  },

  string: {
    matches: ({
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.String.' + path,
      values: { path },
    }),
    min: ({
      min,
      path,
      message,
    }: ValidatorErrorArgs): CustomValidationError => ({
      key: message ?? 'Validation.String.Min',
      values: { min, path },
    }),
  },
};

setLocale(customValidationErrors);
