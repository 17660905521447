import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomValidationError } from '../../localization/validation';

/**
 * Method used for translation fields
 * validation errors
 */
export const translateError = (
  error: unknown
): JSX.Element | undefined | string => {
  if (!error) {
    return undefined;
  }

  if (typeof error !== 'object') {
    console.log(
      `You are working with non-translated error.`,
      `Check \`localization / validation.ts\` for implementation detail.`,
      `Error code: ${error}`
    );

    return error as string;
  }

  const { key, values }: CustomValidationError = error as CustomValidationError;

  return <FormattedMessage id={key} values={values} />;
};
