import * as React from 'react';
import { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useAuthUserQuery } from '../../hooks/useAuthUserQuery';
import { getLink, Links } from '../../links';
import { UserRole } from '../../models/enums/UserRole';
import { FirebaseAuthProxyApi } from '../AuthProvider/api';

interface AuthRouteProps extends RouteProps {
  requiredRole?: UserRole;
}
/**
 * Just check if user is authorized. If not redirect him to login
 * TODO: Implement returnUrl feature later = so user can get back when he ended
 * @param props
 * @constructor
 */
const AuthRoute: FunctionComponent<AuthRouteProps> = (props) => {
  const { requiredRole } = props;

  const { data: user, isLoading: userLoading } = useAuthUserQuery();
  const {
    loading: apiLoading,
  } = FirebaseAuthProxyApi.useFirebaseAuthProxyAPI();
  const location = useLocation();
  const isVerifyEmailPage = location.pathname === getLink(Links.verifyEmail);

  if (!user && !userLoading && !apiLoading) {
    if (isVerifyEmailPage) {
      return <Redirect to={getLink(Links.loginWithEmail)} />;
    } else {
      return <Redirect to={getLink(Links.authSelect)} />;
    }
  }

  const hasAccess = requiredRole
    ? user.roles.includes(requiredRole) || user.roles.includes(UserRole.ADMIN) // Admin has full access
    : true;

  if (!hasAccess) {
    return <Redirect to={getLink(Links.unauthorizedPage)} />;
  }

  return <Route {...props} />;
};

export default AuthRoute;
