import * as React from 'react';
import { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { theme } from '../../resources/theme';

interface LogoVerticalProps {
  size?: number;
  logo: string;
  inverted?: boolean;
}

const LogoVerticalWithLabel: FunctionComponent<LogoVerticalProps> = ({
  size,
  logo,
  inverted,
}) => {
  const color = inverted ? 'white' : theme.palette.secondary.main;
  return (
    <Box display='flex' flexDirection='column' my={3} alignItems='center'>
      <Box mb={2}>
        <img src={logo} alt='Logo' width={size ? size : 80} />
      </Box>
      <Typography align='center'>
        <span
          style={{
            color: color,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Mapa Pomoci
        </span>
      </Typography>
    </Box>
  );
};

export default LogoVerticalWithLabel;
