import React from 'react';
import { render } from 'react-dom';
import Router from '../../router';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from './bootstrap';
import { Provider } from 'react-redux';
import { ReactQueryCacheProvider } from 'react-query';
import DialogContainer from '../../containers/DialogContainer';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import IntlProvider from '../../hoc/IntlProvider';
import { reactQueryCache } from '../config/react-query';
import AlertContainer from '../../containers/AlertContainer';
import { AuthProvider } from '../../components/AuthProvider';
import { theme } from '../../resources/theme';

/**
 * NOTE: Why to use window.onload ?
 * Thanks to code-splitting some of the applications are faster
 * than light and JS is fetched before CSS so you can see unstyled
 * application before styles are loaded. By using window.onload we will
 * wait for all assets required in HTML (<link>, <script> etc.) and when
 * everything is downloaded then we will start the react application.
 *
 * Example situations which are fixed by this:
 * -------------------------------------------------------------------
 * CSS3 animations, scroll/height calculations are wrong due missing styles
 *
 */
window.onload = () =>
  render(
    <Provider store={store}>
      <IntlProvider>
        <ThemeProvider theme={theme}>
          <ReactQueryCacheProvider queryCache={reactQueryCache}>
            {/*<ReactQueryDevtools initialIsOpen={false} />*/}
            <ConnectedRouter history={history}>
              <CssBaseline />
              <AuthProvider>
                <Router />
              </AuthProvider>
              <DialogContainer />
              <AlertContainer />
            </ConnectedRouter>
          </ReactQueryCacheProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>,
    document.getElementById('root')
  );
