import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertApi } from 'api/AlertAPI';
import { push } from 'connected-react-router';
import firebase from 'firebase';
import { getAuthUserQueryKey } from 'hooks/useAuthUserQuery';
import { reactQueryCache } from 'internal/config/react-query';
import { Links } from 'links';
import {
  getAuthErrorNotificationMessage,
  messages,
} from 'localization/messages';
import { ForgotPasswordInterface } from 'models/ForgotPassword';
import { LoginInterface } from 'models/Login';
import { RegisterInterface } from 'models/Register';
import { useSelector } from 'react-redux';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect/src';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { RootState } from '../../internal/redux/reducers';
import { AuthInterface, FirebaseAuthAPI } from '../firebase/FirebaseAuthAPI';

export enum cReducer {
  loading = 'loading',
  initialized = 'initialized',
}

interface InitialStateInterface {
  [cReducer.loading]: boolean;
  [cReducer.initialized]: boolean;
}

export type AuthSignUpAction = PayloadAction<{
  data?: RegisterInterface;
  provider: AuthInterface;
}>;
export type AuthSignInAction = PayloadAction<{
  data?: LoginInterface;
  provider: AuthInterface;
}>;
export type AuthResetPasswordAction = PayloadAction<ForgotPasswordInterface>;

export class FirebaseAuthProxyAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.sagas = this.sagas.bind(this);
    this.signUpSaga = this.signUpSaga.bind(this);
    this.signInSaga = this.signInSaga.bind(this);
    this.resetPasswordSaga = this.resetPasswordSaga.bind(this);
    this.verificationSaga = this.verificationSaga.bind(this);
    this.signOutSaga = this.signOutSaga.bind(this);
    this.synchVerificationSaga = this.synchVerificationSaga.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({
    [cReducer.loading]: true,
    [cReducer.initialized]: false,
  });

  private reducer = {
    signUp(state: InitialStateInterface, action: AuthSignUpAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    signUpFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    signUpRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },
    signIn(state: InitialStateInterface, action: AuthSignInAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    signInFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    signInRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },
    resetPassword(
      state: InitialStateInterface,
      action: AuthResetPasswordAction
    ) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    resetPasswordFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    resetPasswordRejected(
      state: InitialStateInterface,
      action: PayloadAction<Error>
    ) {
      state[cReducer.loading] = false;
    },
    verification(state: InitialStateInterface, action: Action) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    verificationFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    verificationRejected(
      state: InitialStateInterface,
      action: PayloadAction<Error>
    ) {
      state[cReducer.loading] = false;
    },
    signOut(state: InitialStateInterface, action: Action) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    signOutFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    signOutRejected(
      state: InitialStateInterface,
      action: PayloadAction<Error>
    ) {
      state[cReducer.loading] = false;
    },
    synchVerification(state: InitialStateInterface, action: Action) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    synchVerificationFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    synchVerificationRejected(
      state: InitialStateInterface,
      action: PayloadAction<Error>
    ) {
      state[cReducer.loading] = false;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: FirebaseAuthProxyAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryFirebaseAuthProxyAPIName' as string,
    initialState: FirebaseAuthProxyAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeLoadingSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.loading]
  );

  public makeInitializedSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.initialized]
  );

  public useFirebaseAuthProxyAPI() {
    const loading = useSelector(this.makeLoadingSelector);
    const initialized = useSelector(this.makeInitializedSelector);

    return {
      loading: loading && initialized,
    };
  }

  // Saga

  private *signUpSaga(action: AuthSignUpAction) {
    try {
      yield call(() => action.payload.provider.signUp(action.payload.data));
      yield put(this.slice.actions.signUpFulfilled());
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'success',
          message: messages.notifications.auth.registrationSuccess.id,
        })
      );
      yield put(push(Links.servicesPage));

      // if user signed up with an email, send verification email
      if (action.payload.provider === FirebaseAuthAPI.emailAPI) {
        yield put(this.slice.actions.verification());
        const { fulfilled, rejected } = yield race({
          fulfilled: take(this.slice.actions.verificationFulfilled),
          rejected: take(this.slice.actions.verificationRejected),
        });
        if (fulfilled) {
          yield put(
            AlertApi.slice.actions.showAlert({
              type: 'success',
              message: messages.notifications.auth.confirmationEmailSent.id,
            })
          );
        } else if (rejected) {
          yield put(
            AlertApi.slice.actions.showAlert({
              type: 'error',
              message:
                messages.notifications.auth.confirmationEmailSentError.id,
            })
          );
        }
      }
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.signUpRejected(err));
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'error',
          message: getAuthErrorNotificationMessage(err).id,
        })
      );
    }
  }

  private *signInSaga(action: AuthSignInAction) {
    try {
      yield call(action.payload.provider.signIn, action.payload.data);

      // if user signed up with an email, check if its verified
      if (action.payload.provider === FirebaseAuthAPI.emailAPI) {
        if (!firebase.auth().currentUser?.emailVerified) {
          yield put(
            AlertApi.slice.actions.showAlert({
              type: 'warning',
              message:
                messages.notifications.auth.confirmationEmailNotConfirmed.id,
            })
          );
        }
        yield put(this.slice.actions.synchVerification());
        yield race({
          fulfilled: take(this.slice.actions.synchVerificationFulfilled),
          rejected: take(this.slice.actions.synchVerificationRejected),
        });
        yield reactQueryCache.invalidateQueries(getAuthUserQueryKey());
      }
      yield reactQueryCache.invalidateQueries(getAuthUserQueryKey());
      yield put(this.slice.actions.signInFulfilled());
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'success',
          message: messages.notifications.auth.loginSuccess.id,
        })
      );

      yield put(push(Links.servicesPage));
    } catch (err) {
      yield put(this.slice.actions.signInRejected(err));
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'error',
          message: getAuthErrorNotificationMessage(err).id,
        })
      );
    }
  }

  private *resetPasswordSaga(action: AuthResetPasswordAction) {
    try {
      yield call(() => FirebaseAuthAPI.emailAPI.resetPassword(action.payload));
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'success',
          message: messages.notifications.auth.resetPasswordSuccess.id,
        })
      );
      yield put(this.slice.actions.resetPasswordFulfilled());
    } catch (err) {
      console.error(err);
      yield put(
        AlertApi.slice.actions.showAlert({
          type: 'error',
          message: getAuthErrorNotificationMessage(err).id,
        })
      );
      yield put(this.slice.actions.resetPasswordRejected(err));
    }
  }

  private *verificationSaga(action: Action) {
    try {
      yield call(() => FirebaseAuthAPI.emailAPI.verification());

      yield put(this.slice.actions.verificationFulfilled());
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.verificationRejected(err));
    }
  }

  private *signOutSaga(action: Action) {
    try {
      yield call(() => FirebaseAuthAPI.authAPI.signOut());

      yield put(this.slice.actions.signOutFulfilled());
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.signOutRejected(err));
    }
  }

  private *synchVerificationSaga(action: Action) {
    try {
      yield call(() => FirebaseAuthAPI.authAPI.synchVerification());

      yield put(this.slice.actions.synchVerificationFulfilled());
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.synchVerificationRejected(err));
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(this.slice.actions.signUp.type, this.signUpSaga),
        yield takeLatest(this.slice.actions.signIn.type, this.signInSaga),
        yield takeLatest(
          this.slice.actions.resetPassword.type,
          this.resetPasswordSaga
        ),
        yield takeLatest(
          this.slice.actions.verification.type,
          this.verificationSaga
        ),
        yield takeLatest(this.slice.actions.signOut.type, this.signOutSaga),
        yield takeLatest(
          this.slice.actions.synchVerification.type,
          this.synchVerificationSaga
        ),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in FirebaseAuthProxyAPI',
        err
      );
    }
  }
}
