import { Middleware } from 'redux';

/**
 * Middleware List
 * -------------------------------------------------------
 * Inject all your custom middleware here
 * They will be automatically injected into the store
 */
const middleware: Middleware[] = [];

export default middleware;
