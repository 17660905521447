import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, put, race, take, takeLatest } from 'redux-saga/effects';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { messages } from '../../localization/messages';
import { reactQueryCache } from '../../internal/config/react-query';
import { AdminInterface } from '../../models/Admin';
import { CreateAdminFulfilled } from '../../api/proxy/FirebaseAdminProxyAPI';
import { AdminCreateFirebaseAdminProxyAPI } from './createAdminApi';
import { getAdminsQueryKey } from '../../hooks/useAdminsQuery';

interface InitialStateInterface {}

class AdminCreateFlowAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.createAdminSaga = this.createAdminSaga.bind(this);
    this.sagas = this.sagas.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({});

  private reducer = {
    createAdmin(
      state: InitialStateInterface,
      action: PayloadAction<AdminInterface>
    ) {
      return state;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: AdminCreateFlowAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryAdminCreateFormContainerFlowAPIName' as string,
    initialState: AdminCreateFlowAPI.getInitialState(),
    reducers: this.reducer,
  });

  private *createAdminSaga(action: PayloadAction<AdminInterface>) {
    try {
      yield put(
        AdminCreateFirebaseAdminProxyAPI.slice.actions.create(action.payload)
      );

      type Response = {
        fulfilled: CreateAdminFulfilled;
        rejected: PayloadAction<Error>;
      };

      const { fulfilled, rejected } = (yield race({
        fulfilled: take(
          AdminCreateFirebaseAdminProxyAPI.slice.actions.createFulfilled.type
        ),
        rejected: take(
          AdminCreateFirebaseAdminProxyAPI.slice.actions.createRejected.type
        ),
      })) as Response;

      if (fulfilled) {
        console.warn(
          messages.notifications.admin.adminCreatedSuccess.defaultMessage
        );
        yield reactQueryCache.invalidateQueries(getAdminsQueryKey());
        return;
      }

      throw rejected.payload;
    } catch (err) {
      console.error(err);
      console.warn(
        messages.notifications.admin.adminCreatedError.defaultMessage
      );
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(
          this.slice.actions.createAdmin.type,
          this.createAdminSaga
        ),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in AdminCreateFormDialogFlowAPI'
      );
    }
  }
}

export const AdminCreateFlowApi = new AdminCreateFlowAPI(
  'adminCreateFlowApiReducer'
);
