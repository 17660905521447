import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      background: theme.palette.background.default,
      overflow: 'hidden',
      borderRadius: '0.6rem',
      minHeight: '50rem',
    },
    appbar: {
      background: '#fff',
    },
    appbarSidebar: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    appbarContent: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    appbarToolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 12,
      paddingRight: 12,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      zIndex: 25,
    },
    drawerPaper: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: 65,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,

      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      paddingBottom: 40,
    },
    outline: {
      [theme.breakpoints.up('md')]: {
        padding: `1rem 2rem 0rem 2rem`,
        background: theme.palette.secondary.main,
        minHeight: '100vh',
      },
      [theme.breakpoints.up('lg')]: {
        padding: `3rem 4rem 0rem 4rem`,
        background: theme.palette.secondary.main,
        minHeight: '100vh',
      },
    },
    outlineContainer: {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        color: 'white',
      },
    },
    sidebarDrawerButton: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);
