import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ReducerRegister } from '../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../internal/bootstrap/sagaRegistry';
import { MailInterface } from '../models/Mail';
import { FirebaseMailProxyAPI } from './proxy/FirebaseMailProxyAPI';
import { reactQueryCache } from '../internal/config/react-query';
import { getAuthUserQueryKey } from '../hooks/useAuthUserQuery';
import { UserInterface } from '../models/User';

interface InitialStateInterface {}

type MailActionInterface = PayloadAction<MailInterface>;

class MailAPI {
  private readonly reducerName: string;
  private readonly proxy: FirebaseMailProxyAPI;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();
    this.proxy = new FirebaseMailProxyAPI('firebaseMailProxyApiReducer');

    // Saga binding
    this.handleMailSaga = this.handleMailSaga.bind(this);
    this.sagas = this.sagas.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State
  public static getInitialState = (): InitialStateInterface => ({});

  private reducer = {
    handleMail(state: InitialStateInterface, action: MailActionInterface) {
      return state;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: MailAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryMailAPIName' as string,
    initialState: MailAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Saga

  private *handleMailSaga(action: MailActionInterface) {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'TODO: Wow! Some mail happened! Be a good boy and send email or log it somewhere in a cloud... Ya? Good...',
          action
        );
      }

      const authUser = reactQueryCache.getQueryData<UserInterface | undefined>(
        getAuthUserQueryKey()
      );

      if (!authUser) {
        console.error(
          'You are trying to send an email for unauthorized user. MailAPI requires authorized user for correct behaviour.'
        );
        return;
      }

      yield put(
        this.proxy.slice.actions.create({
          ...action.payload,
        })
      );
    } catch (err) {
      console.error(err);
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(
          this.slice.actions.handleMail.type,
          this.handleMailSaga
        ),
      ]);
    } catch (err) {
      console.error('There was an error with saga injection in MailAPI');
    }
  }
}

export const MailApi = new MailAPI('mailApiReducer');
