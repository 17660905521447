import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect/src';
import { RootState } from '../../internal/redux/reducers';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { AdminInterface } from '../../models/Admin';
import FirebaseAdminAPI from '../firebase/FirebaseAdminAPI';

export enum cReducer {
  loading = 'loading',
  initialized = 'initialized',
}

interface InitialStateInterface {
  [cReducer.loading]: boolean;
  [cReducer.initialized]: boolean;
}

export type CreateAdminAction = PayloadAction<AdminInterface>;
export type CreateAdminFulfilled = PayloadAction<AdminInterface>;
export type DeleteAdminAction = PayloadAction<{ id: string }>;

export class FirebaseAdminProxyAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.sagas = this.sagas.bind(this);
    this.createSaga = this.createSaga.bind(this);
    this.deleteSaga = this.deleteSaga.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({
    [cReducer.loading]: true,
    [cReducer.initialized]: false,
  });

  private reducer = {
    create(state: InitialStateInterface, action: CreateAdminAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    createFulfilled(
      state: InitialStateInterface,
      action: CreateAdminFulfilled
    ) {
      state[cReducer.loading] = false;
    },
    createRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },

    delete(state: InitialStateInterface, action: DeleteAdminAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },

    deleteFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    deleteRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: FirebaseAdminProxyAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryFirebaseAdminProxyAPIName' as string,
    initialState: FirebaseAdminProxyAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeLoadingSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.loading]
  );

  public makeInitializedSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.initialized]
  );

  public useFirebaseAdminProxyAPI() {
    const loading = useSelector(this.makeLoadingSelector);
    const initialized = useSelector(this.makeInitializedSelector);

    return {
      loading: loading && initialized,
    };
  }

  // Saga

  private *createSaga(action: CreateAdminAction) {
    try {
      const response = yield call(() =>
        FirebaseAdminAPI.create(action.payload)
      );
      yield put(this.slice.actions.createFulfilled(response));
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.createRejected(err));
    }
  }

  private *deleteSaga(action: DeleteAdminAction) {
    try {
      yield call(() => FirebaseAdminAPI.delete(action.payload.id));

      yield put(this.slice.actions.deleteFulfilled());
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.deleteRejected(err));
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(this.slice.actions.create.type, this.createSaga),
        yield takeLatest(this.slice.actions.delete.type, this.deleteSaga),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in FirebaseAdminProxyAPI',
        err
      );
    }
  }
}

// export default new FirebaseAdminProxyAPI('firebaseAdminProxyApiReducer');
