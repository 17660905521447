import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { DialogApi } from '../../api/DialogAPI';
import { createPortal } from 'react-dom';

const DialogContainer: FunctionComponent = () => {
  const dialogPortalNode = useMemo(
    () => document.getElementById('dialog-root'),
    []
  );

  const dialogRenderComponent = useSelector(
    DialogApi.makeDialogRenderSelector()
  );
  const dialogRender = dialogRenderComponent && dialogRenderComponent();

  return createPortal(
    <>
      <ReactModal
        ariaHideApp={false}
        overlayClassName='none'
        bodyOpenClassName='none'
        className='none'
        shouldFocusAfterRender={false}
        shouldCloseOnEsc
        isOpen
      >
        {/* React-modal is blocking "tab" key due some wierd issue */}
        {/* With solution down below only the invisible input is blocked, others are not */}
        {/* In case you are brave enough you may remove the invisible input and test it */}
        {/* If it's working, then don't tell anyone and keep it as a secret. 👍👍👍 */}
        <input
          style={{
            height: 0,
            border: 'none',
            padding: 0,
            margin: 0,
            position: 'absolute',
          }}
        />

        {dialogRender}
      </ReactModal>
    </>,
    dialogPortalNode as HTMLElement
  );
};

export default DialogContainer;
