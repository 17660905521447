import { Address, AddressInterface } from './Address';
import { User, UserInterface } from './User';
import { RequestStatus } from './enums/RequestStatus';

export enum cService {
  id = 'id',
  status = 'status',
  createdBy = 'createdBy',
  capacityTotal = 'capacityTotal',
  capacityFree = 'capacityFree',
  categoryId = 'categoryId',
  address = 'address',
  managers = 'managers',
  managerIds = 'managerIds',
  organizationName = 'organizationName',
  capacityNote = 'capacityNote',
  website = 'website',
  phone = 'phone',
  email = 'email',
  createdAt = 'createdAt',
}

export interface ServiceInterface {
  id: string;
  status: RequestStatus;
  createdBy: UserInterface;
  capacityTotal: number;
  capacityFree: number;
  categoryId: number;
  address: AddressInterface;
  managers: UserInterface[];
  managerIds: string[];
  organizationName: string;
  capacityNote: string;
  website: string;
  phone: string;
  email: string;
  createdAt: Date;
}

export const Service = (): ServiceInterface => ({
  id: '',
  status: RequestStatus.REQUESTED,
  createdBy: User(),
  capacityTotal: 0,
  capacityFree: 0,
  categoryId: 0,
  address: Address(),
  managers: [],
  managerIds: [],
  organizationName: '',
  capacityNote: '',
  website: '',
  phone: '',
  email: '',
  createdAt: new Date(),
});
