// Just make sure we have env properties loaded, cause we are using this setup through CLI too
/* eslint-disable import/first */
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

import firebase from 'firebase';

/**
 * CONFIGURATION
 * api security is handling by rules, so apiKey is only for connecting to correct firebase project
 * and in that case it can be public
 */
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Must be initialized on start
firebase.initializeApp(config);

// Check if app is running locally, if so work with emulator instead
// Also check if window exists, cause we are using it for database seeding through CLI
// In case window (browser) doesnt exists, always work with firebase emulator
if (process.env.NODE_ENV === 'development') {
  if (process.env.DB_CLEAR || process.env.DB_SEED) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('firebase-admin').initializeApp(config);
  }

  firebase.auth().useEmulator('http://localhost:9099');
  firebase.firestore().useEmulator('localhost', 8080);

  process.env.FIRESTORE_EMULATOR_HOST = 'localhost:8000';
  process.env.FIREBASE_AUTH_EMULATOR_HOST = 'localhost:9099';
}
