import { FunctionComponent } from 'react';
import { Box, DialogTitle, IconButton } from '@material-ui/core';
import * as React from 'react';
import { Close } from '@material-ui/icons';

interface DialogHeader {
  title: string;
  onClose: () => void;
}

const DialogHeader: FunctionComponent<DialogHeader> = ({ title, onClose }) => {
  return (
    <DialogTitle id='alert-dialog-title'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        {title}
        <IconButton size={'small'} onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};

export default DialogHeader;
