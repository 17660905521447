import * as React from 'react';
import { FunctionComponent } from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { messages } from '../../localization/messages';
import { useIntl } from 'react-intl';
import { UserRole } from '../../models/enums/UserRole';
import { Autocomplete } from '@material-ui/lab';
import FormikDebug from '../FormikDebug';
import {
  FormikErrors,
  FormikTouched,
  useField,
  useFormikContext,
} from 'formik';
import { useAuthUserQuery } from '../../hooks/useAuthUserQuery';
import { UserInterface } from '../../models/User';

interface RoleSelectProps {
  name: string;
  errors: FormikErrors<UserInterface>;
  touched: FormikTouched<UserInterface>;
}

const RoleSelect: FunctionComponent<RoleSelectProps> = ({
  name,
  errors,
  touched,
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { formatMessage } = useIntl();
  let roleArray = Object.values(UserRole);
  const { data: user } = useAuthUserQuery();

  if (!user.roles.includes(UserRole.ADMIN)) {
    roleArray = roleArray.filter((role) => role !== UserRole.ADMIN);
  }

  return (
    <FormControl
      size='small'
      fullWidth
      variant='outlined'
      required
      error={!!errors.roles}
    >
      <Autocomplete
        options={roleArray}
        getOptionLabel={(option: UserRole) =>
          formatMessage(messages.enums.userRole[option])
        }
        {...field}
        onChange={(_, values) => setFieldValue(field.name, values)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errors.roles}
            label={formatMessage(messages.models.users.roles)}
            variant='outlined'
            fullWidth
            size={'small'}
          />
        )}
        multiple
      />
      <FormHelperText hidden={!errors.roles}>
        {formatMessage(messages.unique.forms.mandatoryField)}
      </FormHelperText>
      <FormikDebug />
    </FormControl>
  );
};

export default RoleSelect;
