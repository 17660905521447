import firebase from 'firebase';
import { MailInterface } from '../../models/Mail';

interface MailApiInterface {
  create: (src: MailInterface) => Promise<void>;
}

class FirebaseMailAPI implements MailApiInterface {
  private collection = 'mails';

  create(src: MailInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      const firebaseRef = firebase.firestore().collection(this.collection);

      firebaseRef
        .add(src)
        .then((ref) => {
          ref.get().then((doc) => {
            resolve();
          });
        })
        .catch((err) => reject(err));
    });
  }
}

export default new FirebaseMailAPI();
