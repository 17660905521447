import { all, delay, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNode, useCallback } from 'react';
import { ReducerRegister } from '../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../internal/bootstrap/sagaRegistry';
import { RootState } from '../internal/redux/reducers';
import { v4 as uuid } from 'uuid';

export enum cReducer {
  alerts = 'alerts',
}

export type AlertType = {
  id?: string;
  type: 'error' | 'success' | 'info' | 'warning';
  message: ReactNode;
};

export interface AlertAction {
  message: string;
  type: string;
}

export type RemoveAlertAction = PayloadAction<{
  id: string;
}>;

type InitialStateInterface = {
  [cReducer.alerts]: AlertType[];
};

/**
 * Alerts API
 * --------------------------------------
 * API used for displaying errors in the application
 * It's actions should be dispatched mainly in sagas or middleware
 */
class AlertAPI {
  private reducerName: string;
  private delayBeforeAlertRemove: number;

  constructor(reducerName: string) {
    this.reducerName = reducerName;

    this.slice = this.generateSlice();
    this.delayBeforeAlertRemove = 5000;

    // MUST BE BIND
    this.sagas = this.sagas.bind(this);
    this.handleAlertRemoveSaga = this.handleAlertRemoveSaga.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  private static getInitialState = (): InitialStateInterface => ({
    [cReducer.alerts]: [],
  });

  private reducer = {
    showAlert: (
      state: InitialStateInterface,
      action: PayloadAction<AlertType>
    ) => {
      state[cReducer.alerts].push({ id: uuid(), ...action.payload });
    },
    removeAlert: (state: InitialStateInterface, action: RemoveAlertAction) => {
      state[cReducer.alerts].pop();
    },
    removeLastAlert: (state: InitialStateInterface, action: Action) => {
      state[cReducer.alerts].pop();
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: AlertAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryRiskAPIName' as string,
    initialState: AlertAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeAlertsSelector = createSelector<
    RootState,
    InitialStateInterface,
    AlertType[]
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.alerts]
  );

  public useAlertAPI = () => {
    const dispatch = useDispatch();

    const alerts: AlertType[] = useSelector(this.makeAlertsSelector);

    const removeAlert = useCallback(
      (id: string) => {
        dispatch(this.slice.actions.removeAlert({ id }));
      },
      [dispatch]
    );

    const showAlert = useCallback(
      (alert: AlertType) => {
        dispatch(this.slice.actions.showAlert(alert));
      },
      [dispatch]
    );

    return {
      alerts,
      removeAlert,
      showAlert,
    };
  };

  // Saga

  private *handleAlertRemoveSaga(action: AlertAction) {
    try {
      yield delay(this.delayBeforeAlertRemove);
      yield put(this.slice.actions.removeLastAlert());
    } catch (err) {
      console.error(err);
    }
  }

  private *sagas() {
    try {
      yield all([
        yield takeEvery(
          this.slice.actions.showAlert.type,
          this.handleAlertRemoveSaga
        ),
      ]);
    } catch (err) {
      console.error(err);
    }
  }
}

export const AlertApi = new AlertAPI('alertsApiReducer');
