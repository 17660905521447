import * as React from 'react';
import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AlertApi } from '../../api/AlertAPI';
import { useIntl } from 'react-intl';
import { messages } from '../../localization/messages';

interface AlertContainerProps {}

const AlertContainer: FunctionComponent<AlertContainerProps> = () => {
  const { formatMessage } = useIntl();
  const { alerts, removeAlert } = AlertApi.useAlertAPI();

  const alertsGroup = alerts.map((alert) => (
    <Box key={alert.id} mt={1}>
      <Alert
        onClose={() => removeAlert(alert.id as string)}
        severity={alert.type}
      >
        {formatMessage({
          id: alert.message as string,
          defaultMessage: formatMessage({
            id: messages.common.errors.default.id,
            defaultMessage: 'Unknown or not translated error',
          }),
        })}
      </Alert>
    </Box>
  ));

  return (
    <div
      style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 1000 }}
    >
      {alertsGroup}
    </div>
  );
};

export default AlertContainer;
