import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

// Why returned as any?
// Due dynamic nature of reducers and injection based on dynamic names, it's not possible
// to make static types for root reducer/state.
const reducers = ({
  history,
  asyncReducers,
}: {
  history: History;
  asyncReducers?: Record<string, Reducer>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any =>
  combineReducers({
    // Packages
    router: connectRouter(history) as Reducer,

    // Async reducers
    ...asyncReducers,
  });

export type RootState = ReturnType<typeof reducers>;

export default reducers;
