import * as React from 'react';
import { FunctionComponent } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const UnstyledLink: FunctionComponent<LinkProps> = (props) => {
  return (
    <Link
      {...props}
      style={{ textDecoration: 'none', color: 'inherit', ...props.style }}
    />
  );
};

export default UnstyledLink;
