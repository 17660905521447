import * as React from 'react';
import { ComponentType, FunctionComponent } from 'react';
import { Box } from '@material-ui/core';

interface AuthLayoutProps {}

const AuthLayout: FunctionComponent<AuthLayoutProps> = ({ children }) => {
  return (
    <Box
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      style={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        background: '#59b0b0',
      }}
    >
      {children}
    </Box>
  );
};

export default AuthLayout;

export const withAuthLayout = (
  WrappedComponent: ComponentType
): FunctionComponent => (props) => (
  <AuthLayout>
    <WrappedComponent {...props} />
  </AuthLayout>
);
