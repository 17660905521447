/**
 * FontAwesome Icons
 * Link: https://fontawesome.com
 */
import { library } from '@fortawesome/fontawesome-svg-core';

/**
 * Import selected icons
 */
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faArrowUp,
  faBars,
  faBell,
  faBug,
  faBuilding,
  faCalendarCheck,
  faCalendarTimes,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronRight,
  faClone,
  faConciergeBell,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faFileAlt,
  faFileSignature,
  faHeart,
  faHeartbeat,
  faHeartBroken,
  faInfo,
  faInfoCircle,
  faListAlt,
  faMailBulk,
  faPaperPlane,
  faPen,
  faPlus,
  faQuestion,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faStar,
  faTimes,
  faTrashAlt,
  faUser,
  faUserCog,
  faUserPlus,
  faUsers,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

import { faFrown } from '@fortawesome/free-regular-svg-icons';

/**
 * Inject selected icons into usable icons
 * from components
 */
library.add(
  faPen,
  faFileSignature,
  faUsersCog,
  faChevronCircleLeft,
  faChevronCircleRight,
  faBell,
  faUserCog,
  faUser,
  faConciergeBell,
  faTrashAlt,
  faChevronDown,
  faEllipsisV,
  faCaretDown,
  faDownload,
  faEdit,
  faTimes,
  faEnvelope,
  faBug,
  faPlus,
  faMailBulk,
  faStar,
  faSearch,
  faUserPlus,
  faFrown,
  faSignOutAlt,
  faClone,
  faSortUp,
  faPaperPlane,
  faInfoCircle,
  faSortDown,
  faTrashAlt,
  faHeart,
  faHeartBroken,
  faQuestion,
  faHeartbeat,
  faArrowLeft,
  faArrowRight,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faSpinner,
  faChevronRight,
  faChartBar,
  faTrashAlt,
  faListAlt,
  faDownload,
  faBars,
  faInfo,
  faArrowUp,
  faSignInAlt,
  faUsers,
  faCheckCircle,
  faArrowsAltV,
  faSort,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCalendarTimes,
  faCalendarCheck,
  faFileAlt,
  faCheck,
  faBuilding
);
