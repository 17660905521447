import { QueryCache, ReactQueryConfig } from 'react-query';

export const reactQueryConfig: ReactQueryConfig = {
  queries: {
    retry: 0,
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    forceFetchOnMount: false,
  },
};

export const reactQueryCache = new QueryCache({
  defaultConfig: reactQueryConfig,
});
