export enum cAddress {
  facilityName = 'facilityName',
  street = 'street',
  streetNumber = 'streetNumber',
  city = 'city',
  zipCode = 'zipCode',
  districtCode = 'districtCode',
  regionCode = 'regionCode',
}

export interface AddressInterface {
  facilityName: string;
  street: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  districtCode: number;
  regionCode: number;
}

export const Address = (): AddressInterface => ({
  facilityName: '',
  street: '',
  streetNumber: '',
  city: '',
  zipCode: '',
  districtCode: 0,
  regionCode: 0,
});
