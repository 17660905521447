import * as React from 'react';
import { FunctionComponent } from 'react';

export const DebugPreCode: FunctionComponent<{
  title: string;
  object: unknown;
}> = ({ title, object }) => {
  return (
    <>
      {title}:
      <pre>
        <code>{JSON.stringify(object, null, 2)}</code>
      </pre>
    </>
  );
};
