import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect/src';
import { ReducerRegister } from '../internal/bootstrap/reducerRegistry';
import { RootState } from '../internal/redux/reducers';

export enum cReducer {
  locale = 'locale',
}

interface InitialStateInterface {
  [cReducer.locale]: string;
}

class IntlAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
  }

  public static getInitialState = (): InitialStateInterface => ({
    [cReducer.locale]: 'cs',
  });

  private reducer = {
    setLocale(state: InitialStateInterface, action: PayloadAction<string>) {
      state[cReducer.locale] = action.payload;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: IntlAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryReducerName' as string,
    initialState: IntlAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeLocaleSelector = createSelector<
    RootState,
    InitialStateInterface,
    string
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.locale]
  );
}

export const IntlApi = new IntlAPI('intlApiReducer');
