import firebase from 'firebase';

export interface FirebaseWhereFilter {
  key: string | firebase.firestore.FieldPath;
  opStr: firebase.firestore.WhereFilterOp;
  value: unknown;
}

export interface FirebaseOrderFilter {
  key: string;
  order?: 'asc' | 'desc';
}

export interface FirebaseFilter {
  orderBy?: FirebaseOrderFilter[];
  where?: FirebaseWhereFilter[];
}

export interface FirebasePaginationInterface<D> {
  data: D[];
  docs: firebase.firestore.DocumentData[] | undefined;
}

export interface FirebasePaginationArgs {
  limit?: number;
  filters?: FirebaseFilter;
}

export const withFirebaseFilter = <T extends firebase.firestore.DocumentData>(
  query: T,
  filters: FirebaseFilter
): T => {
  filters?.where?.forEach((filter) => {
    query = query.where(filter.key, filter.opStr, filter.value);
  });

  filters?.orderBy?.forEach((order) => {
    query = query.orderBy(order.key, order.order || 'asc');
  });

  return query;
};
