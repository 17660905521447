import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect/src';
import { RootState } from '../../internal/redux/reducers';
import { UserInterface } from '../../models/User';
import { FirebaseUserApi } from '../firebase/FirebaseUserAPI';
import { ReducerRegister } from '../../internal/bootstrap/reducerRegistry';
import { SagaRegister } from '../../internal/bootstrap/sagaRegistry';
import { AuthChooseRoleFormAdditionalFields } from '../../forms/AuthChooseRoleForm';
import { MailApi } from '../MailAPI';
import { Mail } from '../../models/Mail';
import { UpdateUserAdditionalFields } from '../../containers/ApproveUsersPageContainer';
import { RequestStatus } from '../../models/enums/RequestStatus';

export enum cReducer {
  loading = 'loading',
  initialized = 'initialized',
}

interface InitialStateInterface {
  [cReducer.loading]: boolean;
  [cReducer.initialized]: boolean;
}

export type UpdateUserFulfilled = Action;
export type UpdateUserAction = PayloadAction<
  UserInterface & UpdateUserAdditionalFields
>;
export type InitialUserRoleSelectionAction = PayloadAction<
  UserInterface & AuthChooseRoleFormAdditionalFields
>;
export type InitialUserRoleSelectionFulfilled = Action;
export type DeleteUserAction = PayloadAction<{ id: string }>;

export class FirebaseUserProxyAPI {
  private readonly reducerName: string;

  constructor(reducerName: string) {
    this.reducerName = reducerName;
    this.slice = this.generateSlice();

    // Saga binding
    this.sagas = this.sagas.bind(this);
    this.updateSaga = this.updateSaga.bind(this);
    this.initialRoleSelectionSaga = this.initialRoleSelectionSaga.bind(this);
    this.deleteSaga = this.deleteSaga.bind(this);

    // Register async reducer and saga
    ReducerRegister.register(this.reducerName, this.slice.reducer);
    SagaRegister.register(this.reducerName, this.sagas);
  }

  // State

  public static getInitialState = (): InitialStateInterface => ({
    [cReducer.loading]: true,
    [cReducer.initialized]: false,
  });

  private reducer = {
    initialRoleSelection(
      state: InitialStateInterface,
      action: InitialUserRoleSelectionAction
    ) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    initialRoleSelectionFulfilled(
      state: InitialStateInterface,
      action: InitialUserRoleSelectionFulfilled
    ) {
      state[cReducer.loading] = false;
    },

    initialRoleSelectionRejected(
      state: InitialStateInterface,
      action: PayloadAction<Error>
    ) {
      state[cReducer.loading] = false;
    },

    update(state: InitialStateInterface, action: UpdateUserAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },
    updateFulfilled(state: InitialStateInterface, action: UpdateUserFulfilled) {
      state[cReducer.loading] = false;
    },

    updateRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },

    delete(state: InitialStateInterface, action: DeleteUserAction) {
      state[cReducer.initialized] = true;
      state[cReducer.loading] = true;
    },

    deleteFulfilled(state: InitialStateInterface, action: Action) {
      state[cReducer.loading] = false;
    },
    deleteRejected(state: InitialStateInterface, action: PayloadAction<Error>) {
      state[cReducer.loading] = false;
    },
  };

  private generateSlice = () =>
    createSlice({
      name: this.reducerName,
      initialState: FirebaseUserProxyAPI.getInitialState(),
      reducers: this.reducer,
    });

  public slice = createSlice({
    name: 'temporaryFirebaseUserProxyAPIName' as string,
    initialState: FirebaseUserProxyAPI.getInitialState(),
    reducers: this.reducer,
  });

  // Selectors

  public makeLoadingSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.loading]
  );

  public makeInitializedSelector = createSelector<
    RootState,
    InitialStateInterface,
    boolean
  >(
    (state) => state[this.reducerName],
    (state) => state[cReducer.initialized]
  );

  public useFirebaseUserProxyAPI() {
    const loading = useSelector(this.makeLoadingSelector);
    const initialized = useSelector(this.makeInitializedSelector);

    return {
      loading: loading && initialized,
    };
  }

  // Saga

  private *initialRoleSelectionSaga(action: InitialUserRoleSelectionAction) {
    try {
      yield call(() => FirebaseUserApi.update(action.payload));
      yield put(this.slice.actions.initialRoleSelectionFulfilled());

      const toUids: string[] = action.payload.admins;
      const data = action.payload;
      delete data['admins'];

      yield put(
        MailApi.slice.actions.handleMail(
          Mail({
            toUids: toUids,
            template: {
              name: 'userCreated',
              data: data,
            },
          })
        )
      );
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.initialRoleSelectionRejected(err));
    }
  }

  private *updateSaga(action: UpdateUserAction) {
    try {
      const originalStatus: RequestStatus = action.payload.originalStatus;
      const data = action.payload;
      delete data['originalStatus'];
      yield call(() => FirebaseUserApi.update(data));

      if (originalStatus !== action.payload.status) {
        yield put(
          MailApi.slice.actions.handleMail(
            Mail({
              toUids: [action.payload.id],
              template: {
                name:
                  action.payload.status === RequestStatus.ACTIVE
                    ? 'userStatusActive'
                    : 'userStatusDisabled',
                data: action.payload,
              },
            })
          )
        );
      }

      yield put(this.slice.actions.updateFulfilled());
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.updateRejected(err));
    }
  }

  private *deleteSaga(action: DeleteUserAction) {
    try {
      yield call(() => FirebaseUserApi.delete(action.payload.id));

      yield put(this.slice.actions.deleteFulfilled());
    } catch (err) {
      console.error(err);
      yield put(this.slice.actions.deleteRejected(err));
    }
  }

  public *sagas(): Generator {
    try {
      yield all([
        yield takeLatest(this.slice.actions.update.type, this.updateSaga),
        yield takeLatest(
          this.slice.actions.initialRoleSelection.type,
          this.initialRoleSelectionSaga
        ),
        yield takeLatest(this.slice.actions.delete.type, this.deleteSaga),
      ]);
    } catch (err) {
      console.error(
        'There was an error with saga injection in FirebaseUserProxyAPI',
        err
      );
    }
  }
}

// export default new FirebaseUserProxyAPI('firebaseUserProxyApiReducer');
