import * as React from 'react';
import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';

interface CardFieldProps {
  title: string;
  value: React.ReactNode;
}

const CardField: FunctionComponent<CardFieldProps> = ({ title, value }) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      borderBottom='1px solid #EEEEEE'
      py={0.5}
    >
      <strong>{`${title}: `}</strong>
      {value}
    </Box>
  );
};

export default CardField;
