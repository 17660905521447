import * as React from 'react';
import { FunctionComponent } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

interface SpinnerProps {
  inverted?: boolean;
}

const SuspenseSpinner: FunctionComponent<SpinnerProps> = ({ inverted }) => {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justify='center'
      style={{ minHeight: '50vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress color={inverted ? 'inherit' : 'primary'} />
      </Grid>
    </Grid>
  );
};

export default SuspenseSpinner;
