import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { useFormikContext } from 'formik';
import { DebugPreCode } from '../DebugPreCode';

const FormikDebug: FunctionComponent = () => {
  const { values, errors, ...rest } = useFormikContext();
  const [hide, setHide] = useState(true);

  if (hide) return null;

  return (
    <div>
      <button
        color='primary'
        onClick={() => setHide(true)}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        Hide form debug
      </button>
      <div>
        <DebugPreCode title='Values' object={values} />
        <DebugPreCode title='Errors' object={errors} />
        <DebugPreCode title='Rest' object={rest} />
      </div>
    </div>
  );
};

export default FormikDebug;
