import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { Saga } from 'redux-saga';
import reducers from '../redux/reducers';
import middlewares from '../middlewares';
import { routerMiddleware } from 'connected-react-router';
import { ReducerRegister } from './reducerRegistry';
import { SagaRegister } from './sagaRegistry';

const bootstrap = () => {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    ...middlewares,
    sagaMiddleware,
    routerMiddleware(history),
  ];

  const store = configureStore({
    reducer: reducers({ history }),
    middleware: [routerMiddleware(history), ...middleware],
  });

  // Register listeners for async sagas
  SagaRegister.setChangeListener((saga: Saga) => sagaMiddleware.run(saga));

  // Register listeners for async reducers
  ReducerRegister.setChangeListener((asyncReducers) =>
    store.replaceReducer(
      reducers({
        history,
        asyncReducers,
      })
    )
  );

  return { history, store };
};

export const { history, store } = bootstrap();
