import { useQuery } from 'react-query';
import FirebaseAdminAPI from '../api/firebase/FirebaseAdminAPI';
import { AdminInterface } from '../models/Admin';

export const getAdminsQueryKey = () => ['getAdmins'];

export const useAdminsQuery = () => {
  const { isLoading, error, data } = useQuery<AdminInterface[]>(
    getAdminsQueryKey(),
    () => FirebaseAdminAPI.getAll()
  );

  return {
    isLoading,
    error,
    data,
  };
};
