import { UserRole } from './models/enums/UserRole';

export const Links: LinksInterface = {
  // AUTH //
  authSelect: '/auth-select',
  loginWithEmail: '/login-by-email',
  createAccount: '/create-account',
  forgotPassword: '/forgotPassword',
  verifyEmail: '/verify-email',
  awaitingApprovalPage: '/awaiting-approval',
  chooseRolePage: '/choose-role',
  myRequestsPage: '/my-requests-page',

  // PUBLIC //
  servicesPage: '/services-page',
  notFoundPage: '/404',

  // USERS //
  unauthorizedPage: '/unauthorized',

  // MANAGERS //
  myServicesPage: '/my-services-page',
  approveBookServiceRequests: '/approve-book-service-request',

  // ADMINS //
  approveServicesPage: '/approve-services-page',
  approveUsersPage: '/approve-users-page',
  services: '/services',
  serviceManagementPage: '/service-management-page',
  userManagementPage: '/user-management-page',

  loginCallback: `/login/idp`,
  adminUsers: '/admin/users',
};

interface LinkWithArgsType {
  link: string;
  parser: string;
}

interface LinksInterface {
  // AUTH //
  authSelect: string;
  loginWithEmail: string;
  createAccount: string;
  forgotPassword: string;
  verifyEmail: string;
  myRequestsPage: string;
  awaitingApprovalPage: string;
  chooseRolePage: string;

  // PUBLIC //
  servicesPage: string;
  notFoundPage: string;

  // USERS //
  unauthorizedPage: string;

  // MANAGERS //
  myServicesPage: string;
  approveBookServiceRequests: string;
  // ADMINS //
  approveServicesPage: string;
  approveUsersPage: string;
  services: string;
  serviceManagementPage: string;
  userManagementPage: string;

  loginCallback: string;

  adminUsers: string;
}

export const getLinkByRole = (userRole: UserRole): string => {
  switch (userRole) {
    case UserRole.ADMIN:
      return Links.servicesPage;
    case UserRole.USER:
      return Links.servicesPage;
    case UserRole.MANAGER:
      return Links.servicesPage;
  }
};

/**
 * Getter for page links
 * @returns {string}
 * @param link
 * @param args
 */
export const getLink = (
  link: string | LinkWithArgsType,
  args?: string[]
): string => {
  if (typeof link === 'object') {
    if (args) {
      const argsCount: undefined[] = Array.from(Array(args.length));
      let updatedLink: string = link.parser;

      argsCount.forEach((_: undefined, i: number) => {
        updatedLink = updatedLink.replace(`{${i}}`, args[i]);
      });

      return updatedLink;
    }

    return link.link;
  }

  return link;
};
