import * as yup from 'yup';
import { UserInterface } from '../models/User';
import { UserRole } from '../models/enums/UserRole';
import { RequestStatus } from '../models/enums/RequestStatus';
import { Regex } from '../utils/regex';

const authorSchema = yup
  .object<UserInterface>()
  .shape({
    id: yup.string().required(),
    phone: yup.string().matches(Regex.phone).nullable(),
    email: yup.string().matches(Regex.email).required(),
    roles: yup
      .array<UserRole>()
      .of(yup.mixed<UserRole>().oneOf(Object.values(UserRole)))
      .required(),
    fullName: yup.string().required(),
    createdAt: yup.date().required(),
    verified: yup.boolean().required(),
    status: yup
      .mixed<RequestStatus>()
      .oneOf(Object.values(RequestStatus))
      .required(),
  })
  .required();

export default authorSchema;
