import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../redux/reducers';

type ReducerType = (state: RootState, action: AnyAction) => RootState;

/**
 * Service used for registering reducers on runtime. Instead of registering manually
 * reducers for each API, the reducer will be only one with multiple states stored
 * under different unique keys
 */
class _ReducerRegister {
  private emitChange: {
    (arg: { [x: string]: Reducer }): void;
    (reducers: Record<string, Reducer>): void;
  } | null;

  private reducers: Record<string, Reducer>;

  constructor() {
    this.emitChange = null;
    this.reducers = {};
  }

  public getReducers() {
    return { ...this.reducers };
  }

  public register(name: string, reducer: ReducerType) {
    this.reducers = { ...this.reducers, [name]: reducer };
    if (this.emitChange) {
      this.emitChange(this.getReducers());
    }
  }

  public setChangeListener(
    listener: (reducers: Record<string, Reducer>) => void
  ) {
    this.emitChange = listener;
    this.emitChange(this.getReducers());
  }
}

export const ReducerRegister = new _ReducerRegister();
