/* eslint-disable max-len */

import firebase from 'firebase';

/**
 * Please keep the structure separated and extend it if necessary.
 * Current structure include root sections:
 * -----------------------------------------------------
 * Common - Translations used between multiple entities, shared fields/actions mostly - ex. name, date, save, edit ...
 * Actions - Translations related to model actions - ex. program - Finalize Program, layer - Add Layer ...
 * Collections - Translations for collections, ex. empty list of models (program/layer/risks...) ...
 * Modals - Translations related to Dialogs/dialogs
 * Models - Translations related to models and it's fields, some params should be defined in common like name, date...
 * Enums - Translations related to enums
 * Unique - Translations related to separated pages/forms like titles, description for page sections
 */
export interface TranslateMessage {
  id: string;
  defaultMessage: string;
}

export const getAuthErrorNotificationMessage = (
  error: firebase.auth.AuthError
): TranslateMessage => {
  if (!error.code) return messages.common.errors.default;
  const errorCode: string = error.code.slice(5);
  const message =
    messages.notifications.auth[
      errorCode as keyof typeof messages.notifications.auth
    ];
  return message || messages.common.errors.default;
};

export const messages = {
  auth: {
    unauthorized: {
      id: 'Auth.Unauthorized',
      defaultMessage: 'Unauthorized',
    },
  },
  // Common
  common: {
    errors: {
      default: {
        id: 'Common.Errors.Default',
        defaultMessage: 'Something got wrong',
      },
      notFound: {
        id: 'Common.Errors.NotFound',
        defaultMessage: 'Not found',
      },
      fetchError: {
        id: 'Common.Errors.FetchError',
        defaultMessage: 'There was an error while retrieving data',
      },
    },
    fields: {
      action: {
        id: 'Common.Fields.Action',
        defaultMessage: 'Action',
      },
      id: {
        id: 'Common.Fields.ID',
        defaultMessage: 'Id',
      },
      state: {
        id: 'Common.Fields.State',
        defaultMessage: 'State',
      },
      created: {
        id: 'Common.Fields.Created',
        defaultMessage: 'Created',
      },
      updated: {
        id: 'Common.Fields.Updated',
        defaultMessage: 'Updated',
      },
    },
    actions: {
      cancel: {
        id: 'Common.Actions.Cancel',
        defaultMessage: 'Cancel',
      },
      detail: {
        id: 'Common.Actions.Detail',
        defaultMessage: 'Detail',
      },
      close: {
        id: 'Common.Actions.Close',
        defaultMessage: 'Close',
      },
      edit: {
        id: 'Common.Actions.Edit',
        defaultMessage: 'Edit',
      },
      remove: {
        id: 'Common.Actions.Remove',
        defaultMessage: 'Remove',
      },
      save: {
        id: 'Common.Actions.Save',
        defaultMessage: 'Save',
      },
      delete: {
        id: 'Common.Actions.Delete',
        defaultMessage: 'Delete',
      },
      previous: {
        id: 'Common.Actions.Previous',
        defaultMessage: 'Previous',
      },
      next: {
        id: 'Common.Actions.Next',
        defaultMessage: 'Next',
      },
      search: {
        id: 'Common.Actions.Search',
        defaultMessage: 'Search',
      },
      send: {
        id: 'Common.Actions.Send',
        defaultMessage: 'Send',
      },
      continue: {
        id: 'Common.Actions.Continue',
        defaultMessage: 'Continue',
      },
      login: {
        id: 'Common.Actions.Login',
        defaultMessage: 'Login',
      },
      logout: {
        id: 'Common.Actions.Logout',
        defaultMessage: 'Logout',
      },
      back: {
        id: 'Common.Actions.Back',
        defaultMessage: 'Back',
      },
      update: {
        id: 'Common.Actions.Update',
        defaultMessage: 'Update',
      },
      exit: {
        id: 'Common.Actions.Exit',
        defaultMessage: 'Exit',
      },
      download: {
        id: 'Common.Actions.Download',
        defaultMessage: 'Download',
      },
      approve: {
        id: 'Common.Actions.Approve',
        defaultMessage: 'Approve',
      },
      reject: {
        id: 'Common.Actions.Reject',
        defaultMessage: 'Reject',
      },
      submit: {
        id: 'Common.Actions.Submit',
        defaultMessage: 'Submit',
      },
      fetchMore: {
        id: 'Common.Actions.FetchMore',
        defaultMessage: 'Fetch more',
      },
    },

    confirmation: {
      yes: {
        id: 'Common.Confirmation.Yes',
        defaultMessage: 'Yes',
      },
      no: {
        id: 'Common.Confirmation.No',
        defaultMessage: 'No',
      },
      deleteConfirmation: {
        id: 'Common.Confirmation.DeleteConfirmation',
        defaultMessage: 'Are you sure you want to delete this item?',
      },
    },
  },

  // Collections

  collections: {
    common: {
      noOptions: {
        id: 'Collections.Common.NoOptions',
        defaultMessage: 'No options',
      },
      noResultsFound: {
        id: 'Collections.Common.NoResultsFound',
        defaultMessage: 'No results found',
      },
      noMoreResultsFound: {
        id: 'Collections.Common.NoMoreResultsFound',
        defaultMessage: 'No more results found',
      },
      contacts: {
        id: 'Collections.Common.Contacts',
        defaultMessage: 'Contacts',
      },
    },
  },

  // Notifications
  notifications: {
    email: {
      sent: {
        id: 'Notification.Email.Sent',
        defaultMessage: 'Email sent',
      },
      notSent: {
        id: 'Notification.Email.NotSent',
        defaultMessage: 'Email was not sent',
      },
    },
    auth: {
      // FIREBASE AUTH ERRORS START
      'wrong-password': {
        id: 'Notifications.Auth.WrongPassword',
        defaultMessage:
          'The password is invalid or the user does not have a password.',
      },
      'user-not-found': {
        id: 'Notifications.Auth.UserNotFound',
        defaultMessage:
          'There is no user record corresponding to this identifier. The user may have been deleted.',
      },
      'user-disabled': {
        id: 'Notifications.Auth.UserDisabled',
        defaultMessage:
          'The user account has been disabled by an administrator.',
      },
      'account-exists-with-different-credential': {
        id: 'Notifications.Auth.AccountExistsWithDifferentCredential',
        defaultMessage: 'Account alreadz exists with different credential.',
      },
      'credential-already-in-use': {
        id: 'Notifications.Auth.CredentialAlreadyInUse',
        defaultMessage: 'The credential is already in use by another account.',
      },
      'email-already-in-use': {
        id: 'Notifications.Auth.EmailAlreadyInUse',
        defaultMessage:
          'The email address is already in use by another account.',
      },
      // FIREBASE AUTH ERRORS END

      confirmationEmailNotConfirmed: {
        id: 'Notifications.Auth.ConfirmationEmailNotConfirmed',
        defaultMessage:
          'Confirmation email has not been confirmed yet. You must confirm it first.',
      },
      confirmationEmailSent: {
        id: 'Notifications.Auth.ConfirmationEmailSent',
        defaultMessage: 'Confirmation email has been successfully sent',
      },
      confirmationEmailSentError: {
        id: 'Notifications.Auth.ConfirmationEmailSentError',
        defaultMessage: "There's been an error with sending confirmation email",
      },
      registrationSuccess: {
        id: 'Notifications.Auth.RegistrationSuccess',
        defaultMessage: 'The registration has been successful',
      },
      registrationError: {
        id: 'Notifications.Auth.RegistrationError',
        defaultMessage: "There's been an issue with registration",
      },
      duplicateEmail: {
        id: 'Notifications.Auth.DuplicateEmail',
        defaultMessage: 'This email is already in use',
      },
      loginSuccess: {
        id: 'Notifications.Auth.LoginSuccess',
        defaultMessage: 'The login has been successful',
      },
      loginError: {
        id: 'Notifications.Auth.LoginError',
        defaultMessage: "There's been an issue with Login",
      },
      logoutSuccess: {
        id: 'Notifications.Auth.LogoutSuccess',
        defaultMessage: 'The logout has been successful',
      },
      logoutError: {
        id: 'Notifications.Auth.LogoutError',
        defaultMessage: "There's been an issue with Logout",
      },
      resetPasswordSuccess: {
        id: 'Notifications.Auth.ResetPasswordSuccess',
        defaultMessage: 'The email with reset password link has been sent',
      },
      resetPasswordError: {
        id: 'Notifications.Auth.ResetPasswordError',
        defaultMessage:
          "There's been an issue with sending reset password email",
      },
    },
    user: {
      updateSuccessMessage: {
        id: 'Notifications.User.UpdateSuccessMessage',
        defaultMessage: 'User has been successfully updated',
      },
      updateErrorMessage: {
        id: 'Notifications.User.UpdateErrorMessage',
        defaultMessage: "There's been a problem while updating a user",
      },
      deleteSuccessMessage: {
        id: 'Notifications.User.DeleteSuccess',
        defaultMessage: 'User has been successfully deleted',
      },
      deleteErrorMessage: {
        id: 'Notifications.User.DeleteError',
        defaultMessage: "There's been a problem while deleting a user",
      },
    },

    service: {
      createSuccessMessage: {
        id: 'Notifications.Service.CreateSuccessMessage',
        defaultMessage: 'Service has been successfully created',
      },
      createErrorMessage: {
        id: 'Notifications.Service.CreateErrorMessage',
        defaultMessage: "There's been a problem while creating a service",
      },
      deleteSuccessMessage: {
        id: 'Notifications.Service.DeleteSuccess',
        defaultMessage: 'Service has been successfully deleted',
      },
      deleteErrorMessage: {
        id: 'Notifications.Service.DeleteError',
        defaultMessage: "There's been a problem while deleting a service",
      },
      updateSuccessMessage: {
        id: 'Notifications.Service.UpdateSuccessMessage',
        defaultMessage: 'Service has been successfully updated',
      },
      updateErrorMessage: {
        id: 'Notifications.Service.UpdateErrorMessage',
        defaultMessage: "There's been a problem while updating a service",
      },
      removeSuccessMessage: {
        id: 'Notifications.Service.RemoveSuccessMessage',
        defaultMessage: 'Service has been successfully removed',
      },
    },
    bookServiceRequest: {
      createSuccessMessage: {
        id: 'Notifications.BookServiceRequest.CreateSuccess',
        defaultMessage: 'Book service request has been successfully created',
      },
      createErrorMessage: {
        id: 'Notifications.BookServiceRequest.CreateError',
        defaultMessage:
          'Book service request has not been successfully created',
      },
      updateSuccessMessage: {
        id: 'Notifications.BookServiceRequest.UpdateSuccessMessage',
        defaultMessage: 'Book service request has been successfully updated',
      },
      updateErrorMessage: {
        id: 'Notifications.BookServiceRequest.UpdateErrorMessage',
        defaultMessage:
          "There's been a problem while updating a book service request",
      },
      deleteSuccessMessage: {
        id: 'Notifications.BookServiceRequest.DeleteSuccess',
        defaultMessage: 'Book service request has been successfully deleted',
      },
      deleteErrorMessage: {
        id: 'Notifications.BookServiceRequest.DeleteError',
        defaultMessage:
          'Book service request has not been successfully deleted',
      },
    },
    admin: {
      adminCreatedSuccess: {
        id: 'Notifications.Admin.AdminCreatedSuccess',
        defaultMessage: 'Admin has been created',
      },
      adminCreatedError: {
        id: 'Notifications.Admin.AdminCreatedError',
        defaultMessage: 'There was an error creating admin',
      },
      adminDeletedSuccess: {
        id: 'Notifications.Admin.AdminDeletedSuccess',
        defaultMessage: 'Admin has been deleted',
      },
      adminDeletedError: {
        id: 'Notifications.Admin.AdminDeletedError',
        defaultMessage: 'There was an error deleting admin',
      },
    },
  },

  // Actions

  actions: {
    auth: {
      singUp: {
        id: 'Actions.Auth.SignUp',
        defaultMessage: 'Sign up',
      },
      signIn: {
        id: 'Actions.Auth.SignIn',
        defaultMessage: 'Sign in',
      },
      resetPassword: {
        id: 'Actions.Auth.ResetForgetPassword',
        defaultMessage: 'Reset password',
      },
      createAccount: {
        id: 'Actions.Auth.CreateAccount',
        defaultMessage: 'Create account',
      },
      sendConfirmationEmailAgain: {
        id: 'Actions.Auth.SendConfirmationEmailAgain',
        defaultMessage: 'Send again',
      },
      signOut: {
        id: 'Actions.Auth.SignOut',
        defaultMessage: 'Sign out',
      },
    },
    service: {
      createService: {
        id: 'Actions.Service.CreateService',
        defaultMessage: 'Create service',
      },
      editService: {
        id: 'Actions.Service.EditService',
        defaultMessage: 'Edit service',
      },
      updateService: {
        id: 'Actions.Service.UpdateService',
        defaultMessage: 'Update service',
      },
    },
    user: {
      updateUser: {
        id: 'Actions.User.UpdateUser',
        defaultMessage: 'Update user',
      },
      updateProfile: {
        id: 'Actions.User.UpdateProfile',
        defaultMessage: 'Update profile',
      },
      updateRole: {
        id: 'Actions.User.UpdateRole',
        defaultMessage: 'Update role',
      },
    },
  },

  // Modals

  dialogs: {
    common: {
      areYouSureConfirmation: {
        id: 'Modals.Common.AreYouSure',
        defaultMessage: 'Are you sure?',
      },
      doYouReallyWantToDoThis: {
        id: 'Modals.Common.DoYouReallyWantToDoThis',
        defaultMessage: 'Do you really want to do this?',
      },
      doYouReallyWantToDiscardChanges: {
        id: 'Modals.Common.DoYouReallyWantToDiscardChanges',
        defaultMessage: 'Do you really want to discard changes?',
      },
    },
  },

  // Models

  models: {
    register: {
      firstName: {
        id: 'Models.Register.FirstName',
        defaultMessage: 'First name',
      },
      lastName: {
        id: 'Models.Register.LastName',
        defaultMessage: 'Last name',
      },
      phone: {
        id: 'Models.Register.Phone',
        defaultMessage: 'Phone',
      },
      email: {
        id: 'Models.Register.Email',
        defaultMessage: 'Email',
      },
      password: {
        id: 'Models.Register.Password',
        defaultMessage: 'Password',
      },
      confirmPassword: {
        id: 'Models.Register.ConfirmPassword',
        defaultMessage: 'Password confirmation',
      },
    },
    address: {
      address: {
        id: 'Models.Address.Address',
        defaultMessage: 'Address',
      },
      facilityName: {
        id: 'Models.Address.FacilityName',
        defaultMessage: 'Service',
      },
      street: {
        id: 'Models.Address.Street',
        defaultMessage: 'Street',
      },
      streetNumber: {
        id: 'Models.Address.StreetNumber',
        defaultMessage: 'Street number',
      },
      city: {
        id: 'Models.Address.City',
        defaultMessage: 'City',
      },
      zipCode: {
        id: 'Models.Address.ZipCode',
        defaultMessage: 'Zip code',
      },
      district: {
        id: 'Models.Address.District',
        defaultMessage: 'District',
      },
      region: {
        id: 'Models.Address.Region',
        defaultMessage: 'Region',
      },
    },

    users: {
      user: {
        id: 'Models.User.User',
        defaultMessage: 'User',
      },
      fullName: {
        id: 'Models.User.FullName',
        defaultMessage: 'Full name',
      },
      createdAt: {
        id: 'Models.User.CreatedAt',
        defaultMessage: 'Created at',
      },
      status: {
        id: 'Models.User.Status',
        defaultMessage: 'Status',
      },
      roles: {
        id: 'Models.User.Roles',
        defaultMessage: 'Roles',
      },
      phone: {
        id: 'Models.User.Phone',
        defaultMessage: 'Phone',
      },
      email: {
        id: 'Models.User.Email',
        defaultMessage: 'Email',
      },
    },
    service: {
      owner: {
        id: 'Models.Service.Manager',
        defaultMessage: 'Manager',
      },
      service: {
        id: 'Models.Service.Service',
        defaultMessage: 'Service',
      },
      createdAt: {
        id: 'Models.Service.CreatedAt',
        defaultMessage: 'Created at',
      },
      status: {
        id: 'Models.Service.Status',
        defaultMessage: 'Status',
      },
      services: {
        id: 'Models.Service.Services',
        defaultMessage: 'Services',
      },
      managers: {
        id: 'Models.Service.Managers',
        defaultMessage: 'Managers',
      },
      capacity: {
        id: 'Models.Service.Capacity',
        defaultMessage: 'Capacity',
      },
      category: {
        id: 'Models.Service.Category',
        defaultMessage: 'Category',
      },
      capacityTotal: {
        id: 'Models.Service.CapacityTotal',
        defaultMessage: 'Total capacity',
      },
      capacityNote: {
        id: 'Models.Service.CapacityNote',
        defaultMessage: 'Capacity Note',
      },
      capacityFree: {
        id: 'Models.Service.FreeCapacity',
        defaultMessage: 'Free capacity',
      },
      organizationName: {
        id: 'Models.Service.OrganizationName',
        defaultMessage: 'Organization name',
      },
      website: {
        id: 'Models.Service.Website',
        defaultMessage: 'Website',
      },
      phone: {
        id: 'Models.Service.Phone',
        defaultMessage: 'Phone',
      },
      email: {
        id: 'Models.Service.Email',
        defaultMessage: 'Email',
      },
    },
    bookServiceRequest: {
      bookServiceRequest: {
        id: 'Models.BookServiceRequest.BookServiceRequest',
        defaultMessage: 'Book service request',
      },
      requestedBookServiceRequests: {
        id: 'Models.BookServiceRequest.RequestedBookServiceRequests',
        defaultMessage: 'Requested requests',
      },
      activeAndRejectedBookServiceRequests: {
        id: 'Models.BookServiceRequest.ActiveAndRejectedBookServiceRequests',
        defaultMessage: 'Other requests',
      },
      createdAt: {
        id: 'Models.BookServiceRequest.CreatedAt',
        defaultMessage: 'Created at',
      },
      capacity: {
        id: 'Models.BookServiceRequest.Capacity',
        defaultMessage: 'Required capacity',
      },
      note: {
        id: 'Models.BookServiceRequest.Note',
        defaultMessage: 'Note',
      },
      reasonRejected: {
        id: 'Models.BookServiceRequest.ReasonRejected',
        defaultMessage: 'Reason rejected',
      },
      status: {
        id: 'Models.BookServiceRequest.Status',
        defaultMessage: 'Status',
      },
    },
  },

  enums: {
    userRole: {
      ADMIN: {
        id: 'Enums.UserRole.Admin',
        defaultMessage: 'Admin',
      },
      USER: {
        id: 'Enums.UserRole.User',
        defaultMessage: 'User',
      },
      MANAGER: {
        id: 'Enums.UserRole.Manager',
        defaultMessage: 'Manager',
      },
    },
    requestStatus: {
      status: {
        id: 'Enums.RequestStatus.Status',
        defaultMessage: 'Status',
      },
      REQUESTED: {
        id: 'Enums.RequestStatus.Requested',
        defaultMessage: 'Requested',
      },
      DISABLED: {
        id: 'Enums.RequestStatus.Disabled',
        defaultMessage: 'Disabled',
      },
      ACTIVE: {
        id: 'Enums.RequestStatus.Active',
        defaultMessage: 'Active',
      },
    },
  },

  // Unique - Pages, Forms
  unique: {
    auth: {
      signInGoogle: {
        id: 'Unique.Auth.SignInGoogle',
        defaultMessage: 'Sign in with Google',
      },
      signInFacebook: {
        id: 'Unique.Auth.SignInFacebook',
        defaultMessage: 'Sign in with Facebook',
      },
      signInEmail: {
        id: 'Unique.Auth.SignInEmail',
        defaultMessage: 'Sign in with Email',
      },
      confirmationEmailTitle: {
        id: 'Unique.Auth.ConfirmationEmailTitle',
        defaultMessage:
          'We have sent you confirmation email. If you didn\'t receive the email click on the button "Send again". If you already confirmed your email, click on the link "Sign in".',
      },
      awaitingApprovalTitle: {
        id: 'Unique.Auth.AwaitingApprovalTitle',
        defaultMessage:
          'Your account has not yet been approved by an administrator. You will be notified via email. ',
      },
      approvalDisabled: {
        id: 'Unique.Auth.ApprovalDisabled',
        defaultMessage:
          'Your account has been disabled by the administrator. Please contact them for more information on address: .',
      },
      chooseRoleTitle: {
        id: 'Unique.Auth.ChooseRoleTitle',
        defaultMessage: 'To finish registration, please choose your role.',
      },
      confirmationEmailBackToApp: {
        id: 'Unique.Auth.ConfirmationEmailBackToApp',
        defaultMessage: 'Back to app',
      },
      passwordReset: {
        id: 'Unique.Auth.PasswordReset',
        defaultMessage:
          'Give us your email and we will send you a link for resetting your password',
      },
    },
    users: {
      roleNotChosen: {
        id: 'Unique.Users.RoleNotChosen',
        defaultMessage: 'This user has not chosen his role yet.',
      },
      approveUsersTitle: {
        id: 'Unique.Users.ApproveUsersTitle',
        defaultMessage: 'Users to approve',
      },
      userDetail: {
        id: 'Unique.Users.UserDetail',
        defaultMessage: 'User detail',
      },
      approveUser: {
        id: 'Unique.Users.ApproveUser',
        defaultMessage: 'Approve user',
      },
      deleteUser: {
        id: 'Unique.Users.DeleteUser',
        defaultMessage: 'Delete user',
      },
      rejectUser: {
        id: 'Unique.Users.RejectUser',
        defaultMessage: 'Reject user',
      },
    },
    services: {
      myServices: {
        id: 'Unique.Services.MyServices',
        defaultMessage: 'My services',
      },
      managersAddableByAdmin: {
        id: 'Unique.Services.ManagersAddableByAdmin',
        defaultMessage:
          'To add new managers, please contant your administrator.',
      },
      serviceDetail: {
        id: 'Unique.Services.ServiceDetail',
        defaultMessage: 'Service detail',
      },
      approveService: {
        id: 'Unique.Services.ApproveService',
        defaultMessage: 'Activate service',
      },
      rejectService: {
        id: 'Unique.Services.RejectService',
        defaultMessage: 'Disable service',
      },
      deleteService: {
        id: 'Unique.Services.DeleteService',
        defaultMessage: 'Delete service',
      },
      availableCapacity: {
        id: 'Unique.Services.AvailableCapacity',
        defaultMessage: 'Available capacity',
      },
      fullCapacity: {
        id: 'Unique.Services.FullCapacity',
        defaultMessage: 'Capacity is full',
      },
      reserved: {
        id: 'Unique.Services.Reserved',
        defaultMessage: 'Reserved',
      },
      requested: {
        id: 'Unique.Services.Requested',
        defaultMessage: 'Requested services',
      },
      serviceManagementApproval: {
        id: 'Unique.Services.ServiceManagementApproval',
        defaultMessage:
          "I agree that i'm competent person for organization capacity management",
      },
      receiveEmailsConfirmation: {
        id: 'Unique.Services.ReceiveEmailsConfirmation',
        defaultMessage:
          'I want to receive emails regarding new users and services',
      },
    },
    bookServiceRequest: {
      bookServiceRequests: {
        id: 'Unique.BookServiceRequest.BookServiceRequests',
        defaultMessage: 'Requests',
      },
      detail: {
        id: 'Unique.BookServiceRequest.Detail',
        defaultMessage: 'Book service requests detail',
      },
      myBookServiceRequests: {
        id: 'Unique.BookServiceRequest.MyBookServiceRequests',
        defaultMessage: 'My book service requests',
      },
      unacceptedBookServiceRequest: {
        id: 'Unique.BookServiceRequest.UnacceptedBookServiceRequest',
        defaultMessage: 'Unaccepted book service requests',
      },
      deleteBookServiceRequest: {
        id: 'Unique.BookServiceRequest.DeleteServiceRequest',
        defaultMessage: 'Delete book service request',
      },
      addBookServiceRequest: {
        id: 'Unique.BookServiceRequest.AddBookServiceRequest',
        defaultMessage: 'Add book service request',
      },
      noNewBookServiceRequests: {
        id: 'Unique.BookServiceRequest.NoNewBookServiceRequests',
        defaultMessage: 'No new book service requests',
      },
    },
    forms: {
      mandatoryField: {
        id: 'Unique.Forms.MandatoryField',
        defaultMessage: 'This field is mandatory',
      },

      //
    },
    pages: {},
    sidebar: {
      services: {
        id: 'Unique.Sidebar.Services',
        defaultMessage: 'Services',
      },
      myServices: {
        id: 'Unique.Sidebar.MyServices',
        defaultMessage: 'My services',
      },
      newServices: {
        id: 'Unique.Sidebar.NewServices',
        defaultMessage: 'New services',
      },
      manageServices: {
        id: 'Unique.Sidebar.ManageServices',
        defaultMessage: 'Manage services',
      },
      newUsers: {
        id: 'Unique.Sidebar.NewUsers',
        defaultMessage: 'New Users',
      },
      manageUsers: {
        id: 'Unique.Sidebar.ManageUsers',
        defaultMessage: 'Manage Users',
      },
      myRequests: {
        id: 'Unique.Sidebar.MyRequests',
        defaultMessage: 'My requests',
      },
      approveBookServiceRequests: {
        id: 'Unique.Sidebar.ApproveBookServiceRequests',
        defaultMessage: 'Requests for approval',
      },
      publicData: {
        id: 'Unique.Sidebar.Public',
        defaultMessage: 'Public data',
      },
      myData: {
        id: 'Unique.Sidebar.MyData',
        defaultMessage: 'My data',
      },
      adminData: {
        id: 'Unique.Sidebar.adminData',
        defaultMessage: 'My admin',
      },
    },
    tooltips: {
      phone: {
        id: 'Unique.Tooltips.Phone',
        defaultMessage: 'Phone',
      },
      email: {
        id: 'Unique.Tooltips.Email',
        defaultMessage: 'Email',
      },
      website: {
        id: 'Unique.Tooltips.Website',
        defaultMessage: 'Website',
      },
    },
    roles: {
      manager: {
        id: 'Unique.Roles.Manager',
        defaultMessage: 'Manager',
      },
      managerDesription: {
        id: 'Unique.Roles.ManagerDescription',
        defaultMessage: 'Can create services.',
      },
      user: {
        id: 'Unique.Roles.User',
        defaultMessage: 'User - can book services.',
      },
      userDescription: {
        id: 'Unique.Roles.UserDescription',
        defaultMessage: 'User - can book services.',
      },
    },
  },
  email: {
    newCapacityRequest: {
      title: {
        id: 'Email.NewCapacityRequest.Title',
        defaultMessage: 'New capacity request',
      },
      message: {
        id: 'Email.NewCapacityRequest.Message',
        defaultMessage:
          '<p>Hello {{recipient.firstName}} {{recipient.lastName}},</p>\n' +
          '<p>\n' +
          '  There is some new request for your service. Please check it\n' +
          '  and process this request.\n' +
          '</p>',
      },
      button: {
        id: 'Email.NewCapacityRequest.Button',
        defaultMessage: 'New capacity request',
      },
    },
  },
};
