import * as React from 'react';
import { FunctionComponent } from 'react';
import { useAuthUserQuery } from '../../hooks/useAuthUserQuery';
import { UserRole } from '../../models/enums/UserRole';

interface AuthComponentProps {
  requiredRole?: UserRole;
}

const AuthComponent: FunctionComponent<AuthComponentProps> = ({
  requiredRole,
  children,
}) => {
  const { data: user, isLoading: userLoading } = useAuthUserQuery();
  const hasAccess =
    !userLoading && requiredRole
      ? user.roles.includes(requiredRole) || user.roles.includes(UserRole.ADMIN) // Admin has full access
      : true;

  if (hasAccess) {
    return <>{children}</>;
  }
  return null;
};

export default AuthComponent;
