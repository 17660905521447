import { push } from 'connected-react-router';
import { RequestStatus } from 'models/enums/RequestStatus';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuthUserQuery } from '../../hooks/useAuthUserQuery';
import { getLink, Links } from '../../links';
import { UserInterface } from '../../models/User';
import SuspenseSpinner from '../SuspenseSpinner';
import { FirebaseAuthProxyApi } from './api';

/**
 * Check if authorized user has verified email and also wait for user fetch, then render router content
 * @param children
 * @constructor
 */
export const AuthProvider: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [auth, setAuth] = useState<UserInterface | null | undefined | false>(
    false
  );
  const { isLoading: userLoading, error, data: user } = useAuthUserQuery();
  const {
    loading: apiLoading,
  } = FirebaseAuthProxyApi.useFirebaseAuthProxyAPI();

  useEffect(() => {
    if (!userLoading && !apiLoading) {
      const isChooseRole = location.pathname === getLink(Links.chooseRolePage);
      const isVerifyEmailPage =
        location.pathname === getLink(Links.verifyEmail);
      const isNoAuthPage =
        location.pathname === getLink(Links.awaitingApprovalPage);

      if (user && user.roles?.length <= 0 && !isChooseRole) {
        dispatch(push(getLink(Links.chooseRolePage)));
      } else if (
        user &&
        !user.verified &&
        !isVerifyEmailPage &&
        !isChooseRole
      ) {
        dispatch(push(getLink(Links.verifyEmail)));
      } else if (
        user &&
        user.status !== RequestStatus.ACTIVE &&
        !isNoAuthPage &&
        !isVerifyEmailPage &&
        !isChooseRole
      ) {
        dispatch(push(getLink(Links.awaitingApprovalPage)));
      }
      setAuth(user);
    }
  }, [dispatch, location, userLoading, user, error, auth, apiLoading]);

  return auth !== false ? <>{children}</> : <SuspenseSpinner />;
};
