import { ServiceInterface } from './Service';
import { BookServiceRequestInterface } from './BookServiceRequest';
import { UserInterface } from './User';

export interface MailMessage {
  subject: string;
  html: string;
}

export interface MailTemplate {
  name: string;
  data:
    | ServiceInterface
    | BookServiceRequestInterface
    | UserInterface
    | undefined;
}

export interface MailInterface {
  toUids: string[];
  template: MailTemplate;
}

export const Mail = (src: Partial<MailInterface>): MailInterface => ({
  toUids: src.toUids ?? [],
  template: src.template ?? {
    name: '',
    data: undefined,
  },
});
