import firebase from 'firebase';
import { ForgotPasswordInterface } from 'models/ForgotPassword';
import { LoginInterface } from 'models/Login';
import { RegisterInterface } from 'models/Register';
import { UserInterface } from 'models/User';
import { getAuthUserQueryKey } from '../../hooks/useAuthUserQuery';
import { reactQueryCache } from '../../internal/config/react-query';
import { RequestStatus } from '../../models/enums/RequestStatus';

export interface AuthInterface {
  signUp: (values?: RegisterInterface) => Promise<void>;
  signIn: (values?: LoginInterface) => Promise<void>;
}

const collection = 'users';

class GoogleAuthAPI implements AuthInterface {
  signIn(): Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((firebaseUser) => {
          //adding user to firestore (if not yet)
          const userRef = firebase
            .firestore()
            .doc(`${collection}/${firebaseUser.user?.uid}`);
          userRef
            .get()
            .then((doc) => {
              if (!doc.exists) {
                const displayName = firebaseUser.user?.displayName as string;
                const email = firebaseUser.user?.email as string;
                const phone = firebaseUser.user?.phoneNumber;
                const photo = firebaseUser.user?.photoURL || undefined;
                const createdAt = new Date();
                const user: UserInterface = {
                  createdAt,
                  email,
                  fullName: displayName,
                  id: doc.id,
                  roles: [],
                  phone,
                  photo,
                  verified: true,
                  status: RequestStatus.REQUESTED,
                  receiveMail: false,
                };
                try {
                  userRef
                    .set(user)
                    .then(() => {
                      resolve();
                    })
                    .catch((error) => {
                      console.error(
                        'Error with creating new user with Google: ',
                        error
                      );
                      reject(error);
                    });
                } catch (error) {
                  console.error(
                    'Error with creating new user with Google: ',
                    error
                  );
                  reject(error);
                }
              }
              resolve();
            })
            .catch((error) => {
              console.error(
                'Error with searching user in Firestore db: ',
                error
              );
              reject(error);
            });
        })
        .catch((error) => {
          console.error('Error with signing in with Google: ', error);
          reject(error);
        });
    });
  }

  signUp(): Promise<void> {
    throw new Error('SignUp not implemented for Google Auth');
  }
}

class FacebookAuthAPI implements AuthInterface {
  signUp(): Promise<void> {
    throw new Error('SignUp not implemented for Facebook Auth');
  }

  signIn(): Promise<void> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((firebaseUser) => {
          //adding user to firestore (if not yet)
          const userRef = firebase
            .firestore()
            .doc(`${collection}/${firebaseUser.user?.uid}`);
          userRef
            .get()
            .then((doc) => {
              if (!doc.exists) {
                const displayName = firebaseUser.user?.displayName as string;
                const email = firebaseUser.user?.email as string;
                const phone = firebaseUser.user?.phoneNumber;
                const photo = firebaseUser.user?.photoURL || undefined;
                const createdAt = new Date();
                const user: UserInterface = {
                  createdAt,
                  email,
                  fullName: displayName,
                  id: doc.id,
                  roles: [],
                  phone,
                  photo,
                  verified: true,
                  status: RequestStatus.REQUESTED,
                  receiveMail: false,
                };
                try {
                  userRef.set(user);
                  reactQueryCache.invalidateQueries(getAuthUserQueryKey());
                  resolve();
                } catch (error) {
                  console.error(
                    'Error with creating new user with Facebook: ',
                    error
                  );
                  reject(error);
                }
              }
              resolve();
            })
            .catch((error) => {
              console.error(
                'Error with searching user in Firestore db: ',
                error
              );
              reject(error);
            });
        })
        .catch((error) => {
          console.error('Error with signing in with Facebook: ', error);
          reject(error);
        });
    });
  }
}

class EmailAuthAPI implements AuthInterface {
  signUp(values?: RegisterInterface): Promise<void> {
    if (!values)
      throw new Error('SignUpWithEmail method requires parameter: values');
    return new Promise((resolve, reject) => {
      //adding user to firebase
      firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then((user) => {
          //adding user to firestore (if not yet)
          const userRef = firebase
            .firestore()
            .doc(`${collection}/${user.user?.uid}`);
          userRef
            .get()
            .then((doc) => {
              if (!doc.exists) {
                const { firstName, lastName, email, phone } = values;
                const createdAt = new Date();
                const user: UserInterface = {
                  createdAt,
                  email,
                  firstName,
                  fullName: `${firstName} ${lastName}`,
                  id: doc.id,
                  lastName,
                  roles: [],
                  phone,
                  verified: false,
                  status: RequestStatus.REQUESTED,
                  receiveMail: false,
                };
                userRef.set(user);
                reactQueryCache.invalidateQueries(getAuthUserQueryKey());
                resolve();
              }
            })
            .catch((error) => {
              console.error(
                'Error with searching user in Firestore db: ',
                error
              );
              reject(error);
            });
        })
        .catch((error) => {
          console.error('Error with signing up with Email: ', error);
          reject(error);
        });
    });
  }

  signIn(values?: LoginInterface): Promise<void> {
    if (!values)
      throw new Error('SignInWithEmail method requires parameter: values');
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => resolve())
        .catch((error) => {
          console.error('Error with signing in with email: ', error);
          reject(error);
        });
    });
  }

  resetPassword(values: ForgotPasswordInterface): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(values.email);
  }

  verification(): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser?.sendEmailVerification()
        .then(() => resolve())
        .catch((error) => {
          console.error('Verification error: ', error);
          reject(error);
        });
    });
  }
}

class AuthAPI {
  getCurrentUser(): Promise<UserInterface | null> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (!firebaseUser) {
          resolve(null);
        } else {
          firebase
            .firestore()
            .doc(`${collection}/${firebaseUser.uid}`)
            .get()
            .then((doc) => {
              const createAtTimestamp: firebase.firestore.Timestamp = doc.data()
                ?.createdAt;

              return resolve({
                ...doc.data(),
                id: doc.id,
                createdAt: createAtTimestamp?.toDate(),
              } as UserInterface);
            })
            .catch((error) => {
              console.error(
                'Error when tried to fetched current user details',
                error
              );
              reject(error);
            });
        }
      });
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => resolve())
        .catch((error) => {
          console.error('Error when tried to sign out', error);
          reject(error);
        });
    });
  }

  // synchronize email verification state on firebase and firestore
  synchVerification(): Promise<void> {
    const firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) throw new Error('No auth user.');
    return new Promise((resolve, reject) => {
      const userRef = firebase
        .firestore()
        .doc(`${collection}/${firebaseUser.uid}`);
      userRef
        .get()
        .then((doc) => {
          const firestoreUser = {
            ...doc.data(),
            id: doc.id,
          } as UserInterface;
          if (firestoreUser?.verified !== firebaseUser.emailVerified) {
            userRef
              .update({
                verified: firebaseUser.emailVerified,
              })
              .then(() => {
                resolve();
              })
              .catch((error) => {
                console.error(
                  'Error updating emailVerified variable in db',
                  error
                );
                reject(error);
              });
          }
          resolve();
        })
        .catch((error) => {
          console.error(
            'Error with account sync between firebase auth and firestore',
            error
          );
          reject(error);
        });
    });
  }
}

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    firebase
      .firestore()
      .doc(`users/${user.uid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          reactQueryCache.invalidateQueries(getAuthUserQueryKey());
        }
      });
  } else {
    reactQueryCache.invalidateQueries(getAuthUserQueryKey());
  }
});

export const FirebaseAuthAPI = {
  googleAPI: new GoogleAuthAPI(),
  facebookAPI: new FacebookAuthAPI(),
  emailAPI: new EmailAuthAPI(),
  authAPI: new AuthAPI(),
};
