import * as React from 'react';
import { FunctionComponent, useRef } from 'react';
import { DialogApi } from '../../api/DialogAPI';
import { useDispatch } from 'react-redux';
import { FormikContextType } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { messages } from '../../localization/messages';
import { useIntl } from 'react-intl';
import DialogHeader from '../../components/DialogHeader';
import { UserInterface } from '../../models/User';
import { UserUpdateFlowApi } from '../../containers/ApproveUsersPageContainer/flow';
import StatusSelect from '../../components/StatusSelect';
import { UserRole } from '../../models/enums/UserRole';
import { AdminCreateFlowApi } from './createAdminFlow';
import { AdminDeleteFlowApi } from './deleteAdminFlow';
import UserUpdateForm from '../../forms/UserUpdateForm';
import { UpdateUserAdditionalFields } from '../../containers/ApproveUsersPageContainer';
import { RequestStatus } from '../../models/enums/RequestStatus';

interface UserUpdateFormDialogInterface {
  user: UserInterface;
  isStatusSelect: boolean;
}

const UserUpdateFormDialog: FunctionComponent<UserUpdateFormDialogInterface> = ({
  user,
  isStatusSelect,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<
    FormikContextType<UserInterface & UpdateUserAdditionalFields>
  >(null);
  const { closeDialog } = DialogApi.useDialogApi();
  const { formatMessage } = useIntl();

  return (
    <Dialog
      open={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogHeader
        title={formatMessage(messages.actions.user.updateProfile)}
        onClose={closeDialog}
      />
      <DialogContent style={{ margin: 0 }}>
        <UserUpdateForm
          user={user}
          formRef={formRef}
          onSubmit={(values) => {
            if (
              values.roles.includes(UserRole.ADMIN) &&
              values.receiveMail &&
              values.status === RequestStatus.ACTIVE
            ) {
              return [
                dispatch(UserUpdateFlowApi.slice.actions.updateUser(values)),
                dispatch(
                  AdminCreateFlowApi.slice.actions.createAdmin({
                    id: values.id,
                    email: values.email,
                  })
                ),
              ];
            } else {
              return [
                dispatch(UserUpdateFlowApi.slice.actions.updateUser(values)),
                dispatch(
                  AdminDeleteFlowApi.slice.actions.deleteAdmin({
                    id: values.id,
                    email: values.email,
                  })
                ),
              ];
            }
          }}
        >
          {isStatusSelect ? (
            <Grid item xs={12}>
              <StatusSelect />
            </Grid>
          ) : null}
        </UserUpdateForm>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => formRef.current?.submitForm()}
          variant='contained'
          disableElevation
          color='primary'
          autoFocus
        >
          {formatMessage(messages.common.actions.send)}
        </Button>
        <Button
          onClick={closeDialog}
          variant='outlined'
          color='primary'
          disableElevation
        >
          {formatMessage(messages.common.actions.cancel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserUpdateFormDialog;
