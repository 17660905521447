import * as React from 'react';
import { FunctionComponent } from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { messages } from '../../localization/messages';
import { useIntl } from 'react-intl';
import FormikSelect from '../FormikFields';
import { cUser } from '../../models/User';
import { RequestStatus } from '../../models/enums/RequestStatus';

const StatusSelect: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const roleArray = Object.values(RequestStatus).filter(
    (status) => status !== RequestStatus.REQUESTED
  );

  return (
    <FormControl size='small' fullWidth variant='outlined'>
      <InputLabel>
        {formatMessage(messages.enums.requestStatus.status)} *
      </InputLabel>
      <FormikSelect
        name={cUser.status}
        label={formatMessage(messages.enums.requestStatus.status)}
      >
        {roleArray.map((status, i) => (
          <MenuItem key={i} value={status}>
            {formatMessage(messages.enums.requestStatus[status])}
          </MenuItem>
        ))}
      </FormikSelect>
    </FormControl>
  );
};

export default StatusSelect;
