import { createMuiTheme, lighten } from '@material-ui/core';

const WHITE = '#ffffff';
const BACKGROUND = '#EEF6F6';
const DARK = '#303840';
const DARK_LIGHTER = '#808080';
const PRIMARY = '#FE654F';
const PRIMARY_DARK = '#EE4A25';
const SECONDARY = '#1EB8AD';
const SECONDARY_DARK = '#1EB8AD';
const ERROR = '#F44336';
const ERROR_DARK = '#DF2D20';
const WARNING = '#F3C969';
const WARNING_DARK = '#F5B62E';
const SUCCESS = '#1EB8AD';
const SUCCESS_DARK = '#04B09C';

const RADIUS = '0.6rem';
const RADIUS_SMALL = '0.4rem';

export const theme = createMuiTheme({
  palette: {
    text: {
      primary: DARK,
      secondary: DARK_LIGHTER,
    },
    primary: {
      light: lighten(PRIMARY, 0.1),
      main: PRIMARY,
      dark: PRIMARY_DARK,
      contrastText: WHITE,
    },
    secondary: {
      light: lighten(SECONDARY, 0.1),
      main: SECONDARY,
      dark: SECONDARY_DARK,
      contrastText: WHITE,
    },
    error: {
      light: lighten(ERROR, 0.1),
      main: ERROR,
      dark: ERROR_DARK,
      contrastText: WHITE,
    },
    warning: {
      light: lighten(WARNING, 0.1),
      main: WARNING,
      dark: WARNING_DARK,
      contrastText: '#222',
    },
    success: {
      light: lighten(SUCCESS, 0.1),
      main: SUCCESS,
      dark: SUCCESS_DARK,
      contrastText: WHITE,
    },
    background: {
      default: BACKGROUND,
    },
  },
  typography: {
    h4: {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: RADIUS_SMALL,
      },
    },
    MuiFormHelperText: {
      root: {
        textAlign: 'center',
      },
    },
    MuiIconButton: {
      root: {
        '&:disabled': {
          color: DARK_LIGHTER,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '0.5rem',
        borderRadius: RADIUS_SMALL,
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: PRIMARY_DARK,
          color: WHITE,
        },
      },
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: SECONDARY_DARK,
          color: WHITE,
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: DARK,
      },
    },
    MuiListSubheader: {
      root: {
        textTransform: 'uppercase',
        fontSize: 11,
      },
    },
    MuiCard: {
      root: {
        borderRadius: RADIUS,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: RADIUS,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '1rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '1.5rem 1rem',
        boxShadow: 'inset 0 0 1rem rgba(0,0,0,0.1)',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '1rem 0.5rem',
      },
    },
    MuiContainer: {
      root: {
        borderRadius: RADIUS,
      },
    },
  },
  // DO YOUR THEME OVERWRITES HERE
  // For correct overwrites check out types in createMuiTheme
});
