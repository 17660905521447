export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  MANAGER = 'MANAGER',
}

export const presentTextUserRole = (role: UserRole) => {
  switch (role) {
    case UserRole.USER:
      return 'user';
    case UserRole.ADMIN:
      return 'admin';
    case UserRole.MANAGER:
      return 'manager';
  }
};

export const presentIconUserRole = (role: UserRole) => {
  switch (role) {
    case UserRole.USER:
      return 'user';
    case UserRole.ADMIN:
      return 'user-cog';
    case UserRole.MANAGER:
      return 'user';
  }
};
