import { compose } from 'redux';
import { connect } from 'react-redux';
import { translations } from '../localization';
import { IntlProvider } from 'react-intl';
import { ComponentType } from 'react';
import { IntlApi } from '../api/IntlAPI';

export default compose<ComponentType>(
  connect((state) => {
    const locale: string = IntlApi.makeLocaleSelector(state);
    return {
      locale,
      messages: translations[locale],
      onError: () => null, // TODO: Remove for translation errors when starting with translations
    };
  })
)(IntlProvider);
